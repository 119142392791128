import React from "react";
import PropTypes from "prop-types";
import {
  Avatar, Select, Space, Spin,
} from "antd";
import _ from "lodash";

import { isEmail } from "../../../utils";
import { getRequest } from "../../../services/request";

const UserAutoComplete = ({ onSelect, ...rest }) => {
  const [searching, setSearching] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  const search = (inputVal) => {
    if (isEmail(inputVal)) {
      setSearching(true);
      const encoded = encodeURI(JSON.stringify({ email: inputVal }));
      getRequest(`/users?filter=${encoded}`).then(({ data }) => {
        let _options;
        if (data.length > 0) {
          _options = data;
        } else {
          _options = [{ email: inputVal }];
        }
        setOptions(_options);
        setSearching(false);
      });
    }
  };

  const debouncedSearch = _.debounce(search, 400);

  const onSelectHandler = (val, { key, value }) => {
    onSelect(val, { key: key || value, value });
    setOptions([]);
  };

  return (
    <Select
      filterOption={false}
      mode="multiple"
      onSearch={debouncedSearch}
      showSearch
      notFoundContent={searching ? <Spin size="small" /> : null}
      onSelect={onSelectHandler}
      style={{ width: "100%" }}
      placeholder="Type email addresses to add"
      {...rest}
    >
      {options.map((option) => (
        <Select.Option key={option.id} value={option.email}>
          {option.id ? (
            <Space direction="horizontal" size="small">
              <Avatar size="small" src={option.photoUrl}>
                {!option.photoUrl
                  ? (option.name || option.email).substring(0, 1).toUpperCase()
                  : null}
              </Avatar>
              {option.email}
            </Space>
          ) : option.email}
        </Select.Option>
      ))}
    </Select>
  );
};

UserAutoComplete.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onDeselect: PropTypes.func.isRequired,
};

export default UserAutoComplete;

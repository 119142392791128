import React from "react";
import { useHistory, useLocation } from "react-router";
import _ from "lodash";
import { Helmet } from "react-helmet";

import { useAntdTable } from "ahooks";
import {
  Card, Col, Row, Button,
} from "antd";

import model from "./model";

import ScheduleTable from "./ScheduleTable";
import { PAGE_SIZE } from "../../constants";

const SchedulesPage = () => {
  const location = useLocation();
  const history = useHistory();

  const { tableProps, refresh } = useAntdTable(
    (params) => model.fetchSchedules(params, location),
    { defaultPageSize: PAGE_SIZE, refreshDeps: [location] },
  );

  return (
    <>
      <Helmet>
        <title>Schedules</title>
        <meta name="description" content="Description of SchedulesPage" />
      </Helmet>
      <Card>

        <Row>
          <Button
            type="primary"
            style={{ marginBottom: "10px" }}
            onClick={() => { history.push("/schedules/new"); }}
          >
            Add new Schedule
          </Button>
        </Row>
        <Row gutter={24}>
          <Col xs={24} lg={24} xl={16}>
            <ScheduleTable
              schedules={tableProps.dataSource}
              loading={tableProps.loading}
              pagination={tableProps.pagination}
              onIdClick={(id) => { history.push(`/schedules/${id}`); }}
              onTableChange={tableProps.onChange}
            />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SchedulesPage;

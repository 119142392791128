import { getRequest } from "../services/request";

const fetchSharedUsers = (user) => {
  const params = new URLSearchParams({
    filter: JSON.stringify({ sharedWithId: user.id }),
  });
  return getRequest(`/shared-users?${params}`);
};

export default { fetchSharedUsers };

import React from "react";
import PropTypes from "prop-types";
import ReactExport from "react-export-excel";
import {
  Button, message, notification, Popconfirm,
} from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { getRequest } from "../../../../services/request";

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;

const columns = ["Timestamp", "Value", "Key"];
const getStyle = (key) => {
  let rgb;
  if (key === "INST") {
    rgb = "FF00FF03";
  } else if (key === "EXST") {
    rgb = "FF9901FE";
  }
  let style;
  if (rgb) {
    style = {
      fill: { patternType: "solid", fgColor: { rgb } },
    };
  }
  return style;
};

const ExportExcel = (props) => {
  const {
    sessionId, startTime, endTime, keys,
  } = props;

  const [inhales, setInhales] = React.useState([]);
  const [exhales, setExhales] = React.useState([]);

  React.useEffect(() => {
    const params = { sessionId, startTime, endTime };
    const promises = ["INST", "EXST"].map((key) => {
      const _params = new URLSearchParams({ ...params, key });
      return getRequest(`/measurements/query?${_params}`);
    });

    Promise.all(promises).then(([_inhales, _exhales]) => {
      setInhales(_inhales.map((e) => ({ timestamp: e[0], value: e[1], key: "INST" })));
      setExhales(_exhales.map((e) => ({ timestamp: e[0], value: e[1], key: "EXST" })));
    });
  }, [sessionId, startTime, endTime]);

  const downloadAndExport = async () => {
    const hide = message.loading("Downloading data...", 0);
    const params = { sessionId, startTime, endTime };
    const promises = keys.map((key) => {
      const _params = new URLSearchParams({ ...params, key });
      return getRequest(`/measurements/query?${_params}`);
    });
    const results = await Promise.all(promises);
    const data = keys.reduce((obj, key, index) => {
      const result = results[index].map((e) => ({ timestamp: e[0], value: e[1], key }));
      result.push(...inhales, ...exhales);
      result.sort((a, b) => a.timestamp - b.timestamp);
      // eslint-disable-next-line no-param-reassign
      obj[key] = result;
      return obj;
    }, {});
    hide();

    const notificationKey = `open${Date.now()}`;
    const btn = (
      <ExcelFile
        filename={sessionId}
        element={(
          <Button type="primary" onClick={() => notification.close(notificationKey)}>
            Export
          </Button>
        )}
      >
        {Object.keys(data).map((key) => (
          <ExcelSheet
            key={key}
            dataSet={[{
              columns,
              data: data[key].map((e) => {
                const style = getStyle(e.key);
                return ([
                  { value: new Date(e.timestamp).toISOString(), style },
                  { value: e.value.toString(), style },
                  { value: e.key.toString(), style },
                ]);
              }),
            }]}
            name={key}
          />
        ))}
      </ExcelFile>
    );
    notification.success({
      message: "Export data",
      description: "Data has been downloaded and is ready for export",
      btn,
      key: notificationKey,
      duration: 0,
    });
  };

  return (
    <Popconfirm
      title={`The data for ${keys.join(", ")} will be downloaded and then made available for export`}
      onConfirm={downloadAndExport}
      onCancel={() => {}}
      okText="Download"
      cancelText="Cancel"
      placement="topRight"
    >
      <Button size="small" icon={<FileExcelOutlined />}>Export data</Button>
    </Popconfirm>
  );
};

ExportExcel.defaultProps = {
  keys: [],
};

ExportExcel.propTypes = {
  sessionId: PropTypes.string.isRequired,
  startTime: PropTypes.number.isRequired,
  endTime: PropTypes.number.isRequired,
  keys: PropTypes.array,
};

export default ExportExcel;

import React, { useEffect } from "react";
import {
  Checkbox, Col, Form, Input, Row,
} from "antd";

const Schedule = ({
  setScheduleData,
  ScheduleName,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      id: ScheduleName.id,
      name: ScheduleName.name,
      description: ScheduleName.description,
      isRepeat: ScheduleName.is_repeat,
    });
  }, [ScheduleName]);

  const handleFormChange = (changedValues, allValues) => {
    setScheduleData(allValues);
  };

  return (
    <Row>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        layout="inline"
        form={form}
        style={{ width: "100%" }}
        onValuesChange={handleFormChange}
      >
        <Col span={8} hidden>
          <Form.Item
            label="id"
            name="id"
          >
            <Input placeholder="Enter name" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Name"
            name="name"
            hasFeedback
            rules={[{ required: true, message: " " }]}
          >
            <Input placeholder="Enter name" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            label="Description"
            name="description"
            hasFeedback
            rules={[{ required: true, message: " " }]}
          >
            <Input placeholder="Write here..." />
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name="isRepeat"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Repeat</Checkbox>
          </Form.Item>
          {/* <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Save
                            </Button>
                        </Form.Item> */}
        </Col>
      </Form>
    </Row>
  );
};

export default Schedule;

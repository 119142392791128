import React from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

const Chart = (props) => {
  const {
    series,
    id,
    height,
    group,
    colors,
    yFormatter,
    min,
    max,
    xaxis,
    markers,
    animations,
    annotations,
    multiple,
    type,
    start,
    end,
  } = props;

  const getYAxis = React.useCallback(
    () =>
      series.slice(0, multiple ? undefined : 1).map((e, index) => {
        const values = e.data
          .map((item) => item[1])
          .filter((item) => item !== null);
        return {
          title: {
            text: multiple ? e.name : series.map((s) => s.name).join(","),
          },
          forceNiceScale: true,
          opposite: index % 2 !== 0,
          labels: {
            minWidth: 40,
            formatter: (v) => yFormatter(e.key, v),
          },

          min: 0,
          // values.length > 0 && multiple
          //   ? Math.min(...values)
          //   : (_min) => _min,
          // max:
          //   values.length > 0 && multiple
          //     ? Math.max(...values)
          //     : (_max) => _max,
        };
      }),
    [series, multiple, yFormatter]
  );

  const minTime = moment.utc(start).valueOf();
  const maxTime = moment.utc(end).valueOf();

  const options = React.useMemo(
    () => ({
      chart: {
        animations,
        id,
        group,
        height,
        toolbar: {
          autoSelected: "zoom",
          show: true,
          tools: {
            download: false,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        zoom: {
          enabled: true,
          type: "x",
          autoScaleYaxis: true,
        },
      },
      colors,
      dataLabels: {
        enabled: false,
      },
      legend: {
        showForNullSeries: false,
      },
      markers,
      stroke: {
        curve: "smooth",
        width: 2,
      },
      xaxis: {
        min: minTime,
        max: maxTime,
        ...xaxis,
      },

      yaxis: getYAxis(),
      annotations,
      tooltip: {
        enabled: true,
        x: {
          show: true,
          formatter: (timestamp) =>
            moment(timestamp).format("DD MMM, HH:mm:ss"),
        },
      },
    }),
    [getYAxis, xaxis]
  );

  return (
    <>
      <ReactApexChart
        options={options}
        series={series}
        height={height}
        type={type}
      />
    </>
  );
};

Chart.defaultProps = {
  height: 360,
  group: undefined,
  colors: undefined,
  yFormatter: undefined,
  min: undefined,
  max: undefined,
  xaxis: {
    type: "datetime",
    tooltip: {
      enabled: false,
    },
  },
  markers: {
    size: 0,
    hover: {
      sizeOffset: 3,
    },
  },
  animations: { enabled: true },
  annotations: { xaxis: [] },
  multiple: true,
  type: "area",
};

Chart.propTypes = {
  id: PropTypes.string.isRequired,
  height: PropTypes.number,
  group: PropTypes.string,
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  yFormatter: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  xaxis: PropTypes.object,
  markers: PropTypes.object,
  animations: PropTypes.object,
  annotations: PropTypes.object,
  multiple: PropTypes.bool,
  type: PropTypes.string,
  start: PropTypes.string,
  end: PropTypes.string,
};

export default Chart;

import React from "react";
import PropTypes from "prop-types";
import { Radio, Space, Switch } from "antd";

const CycleSelector = (props) => {
  const [show, setShow] = React.useState(false);
  const [type, setType] = React.useState("CYCLES");

  const { onShow, onTypeChange } = props;

  const handleShowChange = (checked) => {
    setShow(checked);
    onShow(checked);
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    onTypeChange(e.target.value);
  };

  return (
    <Space size="small">
      <Switch
        checkedChildren="Show cycles"
        unCheckedChildren="Cycles hidden"
        onChange={handleShowChange}
        checked={show}
      />
      {show && (
      <Radio.Group value={type} onChange={handleTypeChange} size="small">
        <Radio.Button value="CYCLES">Cycles</Radio.Button>
        <Radio.Button value="INHALES">Inhales</Radio.Button>
        <Radio.Button value="EXHALES">Exhales</Radio.Button>
      </Radio.Group>
      )}
    </Space>
  );
};

CycleSelector.defaultProps = {
  onShow: () => {},
  onTypeChange: () => {},
};

CycleSelector.propTypes = {
  onShow: PropTypes.func,
  onTypeChange: PropTypes.func,
};

export default CycleSelector;

import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { Card, Col, Row } from "antd";

import UsersList from "./UsersList";
import SessionsList from "./SessionsList";
import FriendsList from "./FriendsList";

const SharingPage = () => {
  const [activeTab, setActiveTab] = React.useState("sessions");

  let content;

  switch (activeTab) {
    case "sessions":
      content = <SessionsList />;
      break;

    case "friends":
      content = <FriendsList />;
      break;

    case "users":
      content = <UsersList />;
      break;

    default:
      break;
  }

  return (
    <div>
      <Helmet>
        <title>Sharing</title>
      </Helmet>
      <Row gutter={24}>
        <Col xs={24}>
          <Card
            tabList={[
              { key: "sessions", tab: "Sessions" },
              { key: "friends", tab: "Friends" },
              { key: "users", tab: "Users" },
            ]}
            onTabChange={(key) => { setActiveTab(key); }}
          >
            {content}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

SharingPage.propTypes = {

};

export default SharingPage;

import { getRequest, postRequest, putRequest } from "../../services/request";

const createScheduleName = async (additionalName) => {
  const data = await postRequest("/schedules", additionalName);
  return data;
};

const fetchSchedule = (scheduleId) => getRequest(`/schedules/${scheduleId}`);

const updateSchedule = async (additionalColumns) => {
  const { data } = await putRequest("/schedules", additionalColumns);
  return data;
};

export default {
  createScheduleName,
  fetchSchedule,
  updateSchedule,
};

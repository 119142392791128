import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import {
  Button, Empty, Table, message,
} from "antd";

const columns = [
  {
    key: "sort",
  },
  {
    title: "CO2%",
    dataIndex: "value",
  },
  {
    title: "Hour",
    dataIndex: "hour",
  },
  {
    title: "Minute",
    dataIndex: "minute",
  },
];

const TableRow = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
        position: "relative",
        zIndex: 9999,
      }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const wait = async (delay = 1000) => new Promise((resolve) => setTimeout(() => resolve(void 0), delay));

const ListSort = ({
  dataSource,
  setDataSource,
}) => {
  const onDragEnd = async ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
      await wait(500);
      message.success("Modify list sorting successfully");
    }
  };

  return (
    <>
      {
        (dataSource?.length || 0) > 0
          ? (
            <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
              <SortableContext
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
              >
                <div style={{ overflowX: "auto" }}>
                  <Table
                    components={{
                      body: {
                        row: TableRow,
                      },
                    }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                  />
                </div>
              </SortableContext>
            </DndContext>
          )
          : <Empty style={{ padding: 16 }} />
      }
    </>
  );
};
export default ListSort;

import React from "react";
import PropTypes from "prop-types";
import {
  Badge, Divider, Modal, Select, Space, Tabs, Tag, Button, message,
} from "antd";
import { EyeOutlined, PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import _ from "lodash";
import { selectors } from "../../App/slice";
import {
  CATEGORY_ACTIVITY, CATEGORY_ENERGY, CATEGORY_STATE, CATEGORY_STRESS_LEVEL,
} from "../../../constants";

const ColumnsButton = (props) => {
  const {
    onOk, onCancel, values,
  } = props;

  const keys = useSelector(selectors.makeSelectKeys());
  const trendsKeys = [...keys.trendsKeys];
  trendsKeys.push({
    label: "Activity",
    children: [
      {
        value: "activityRefId", label: "Activity", unit: "", category: CATEGORY_ACTIVITY,
      },
      {
        value: "stressLevelRefId", label: "Stress Level", unit: "", category: CATEGORY_STRESS_LEVEL,
      },
      {
        value: "energyRefId", label: "Energy", unit: "", category: CATEGORY_ENERGY,
      },
      {
        value: "stateRefId", label: "State", unit: "", category: CATEGORY_STATE,
      },
    ],
    overview: true,
  });
  const children = _.flatMap(trendsKeys.map((e) => e.children));

  const [showModal, setShowModal] = React.useState(false);
  const [selected, setSelected] = React.useState(values || []);
  const [inputVisible, setInputVisible] = React.useState(false);
  const [inputValue, setInputValue] = React.useState("");
  const inputRef = React.useRef();

  React.useEffect(() => {
    setSelected(values || []);
  }, [values]);

  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  const canSelect = (_selected) => {
    if (_selected.length > 4) {
      message.error("Select upto 4 metrics");
      return false;
    }
    return true;
  };

  const handleChange = (key, checked) => {
    const nextSelected = checked ? [...selected, key] : selected.filter((t) => t !== key);
    if (canSelect(nextSelected)) {
      setSelected(nextSelected);
    }
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e);
  };

  const handleInputConfirm = () => {
    const nextSelected = inputValue && selected.indexOf(inputValue) === -1
      ? [...selected, inputValue]
      : null;
    if (nextSelected && canSelect(nextSelected)) {
      setSelected(nextSelected);
      setInputVisible(false);
      setInputValue("");
    }
  };

  const renderCheckableTag = (group) => (
    <Space wrap size={[8, 8]}>
      {trendsKeys
        .find((key) => key.overview && key.label === group)
        ?.children
        ?.map(({ value, label }) => (
          <Tag.CheckableTag
            style={{ marginRight: 0 }}
            key={value}
            checked={selected.indexOf(value) > -1}
            onChange={(checked) => handleChange(value, checked)}
          >
            {label}
          </Tag.CheckableTag>
        ))}
    </Space>
  );

  const renderTabTitle = (group) => {
    const count = keys.keys.filter((key) => key.group === group)
      .filter((key) => selected.includes(key.key))
      .length;
    return (
      <span>
        {group}
        <Badge count={count} showZero={false} style={{ marginLeft: 8 }} />
      </span>
    );
  };

  const handleOk = () => {
    setShowModal(false);
    onOk(_.uniqBy(children.filter((e) => selected.includes(e.value)), "value"));
  };

  const handleCancel = () => {
    setSelected(values);
    setShowModal(false);
    onCancel();
  };

  return (
    <>
      <Button type="primary" onClick={() => { setShowModal(true); }} icon={<EyeOutlined />}>
        Show/Hide columns
      </Button>
      <Modal visible={showModal} title="Show/Hide columns" width={700} onOk={handleOk} onCancel={handleCancel} destroyOnClose>
        <p>Visible columns:</p>
        <Space wrap size={[8, 8]}>
          {selected.map((key) => (
            <Tag
              key={key}
              style={{ marginRight: 0 }}
              closable
              onClose={() => { setSelected(selected.filter((e) => e !== key)); }}
            >
              {children.find((e) => e.value === key)?.label}
            </Tag>
          ))}
          {inputVisible && (
          <Select
            ref={inputRef}
            showSearch
            filterOption={(input, option) => option.children
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0}
            style={{ width: 300 }}
            size="small"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          >
            {children.map(({ value, label }) => (
              <Select.Option key={value} value={value}>
                {label}
              </Select.Option>
            ))}
          </Select>
          )}
          {!inputVisible && (
          <Tag onClick={showInput}>
            <PlusOutlined />
            {" "}
            Add metric
          </Tag>
          )}
        </Space>
        <Divider />
        <Tabs size="small">
          <Tabs.TabPane tab={renderTabTitle("CO2")} key="1">
            {renderCheckableTag("CO2")}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle("O2")} key="2">
            {renderCheckableTag("Oxygen")}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle("Metabolism")} key="3">
            {renderCheckableTag("Metabolism")}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle("Lung Function")} key="4">
            {renderCheckableTag("Lung Function/Breathing")}
          </Tabs.TabPane>
          <Tabs.TabPane tab={renderTabTitle("Activity")} key="5">
            {renderCheckableTag("Activity")}
          </Tabs.TabPane>
        </Tabs>
      </Modal>
    </>
  );
};

ColumnsButton.defaultProps = {
  onCancel: () => {},
};

ColumnsButton.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  values: PropTypes.array.isRequired,
};

export default ColumnsButton;

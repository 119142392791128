import React from "react";
import { Route, Switch } from "react-router";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";

import LoginPage from "../Auth/LoginPage";
import RegisterPage from "../Auth/RegisterPage";
import AuthActionPage from "../Auth/AuthActionPage";
import VerifyPage from "../Auth/VerifyPage";
import ForgotPasswordPage from "../Auth/ForgotPasswordPage";
import ResetPasswordPage from "../Auth/ResetPasswordPage";
import AppLayout from "../Common/AppLayout";
import DashboardPage from "../DashboardPage";
import useAttachReducer from "../../store/useAttachReducer";
import OnboardPage from "../OnboardPage";
import ProfilePage from "../ProfilePage";
import { AuthContext } from "../Auth/AuthContextProvider";
import SessionsPage from "../SessionsPage";
import SettingsPage from "../SettingsPage";
import reducer, { init, selectors } from "./slice";
import Splash from "../Common/Splash";
import SessionPage from "../SessionPage";
import SharingPage from "../SharingPage";
import TrendsPage from "../TrendsPage";
import SchedulesPage from "../SchedulesPage";
import SchedulePage from "../SchedulePage";
import DevicesPage from "../DevicesPage";

const App = () => {
  useAttachReducer({ key: "App", reducer });
  const loading = useSelector(selectors.makeSelectLoading());
  const dispatch = useDispatch();
  const { currentUser } = React.useContext(AuthContext);

  React.useEffect(() => {
    dispatch(
      init({ userId: currentUser && currentUser.uid }),
    );
  }, [dispatch, currentUser]);

  return (
    <>
      <Helmet titleTemplate="%s - HyperCanopy" defaultTitle="HyperCanopy">
        <meta name="description" content="HyperCanopy" />
      </Helmet>
      {loading ? <Splash />
        : (
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/forgot" component={ForgotPasswordPage} />
            <Route exact path="/reset" component={ResetPasswordPage} />
            <Route exact path="/auth/action" component={AuthActionPage} />
            <AppLayout>
              <Route exact path="/verify" component={VerifyPage} />
              <Route exact path="/onboard" component={OnboardPage} />
              <Route exact path="/" component={DashboardPage} />
              <Route exact path="/sessions" component={SessionsPage} />
              <Route exact path="/sessions/:id" component={SessionPage} />
              <Route exact path="/trends" component={TrendsPage} />
              <Route exact path="/schedules" component={SchedulesPage} />
              <Route exact path="/schedules/:id" component={SchedulePage} />
              <Route exact path="/sharing" component={SharingPage} />
              <Route exact path="/profile" component={ProfilePage} />
              <Route exact path="/devices" component={DevicesPage} />
              <Route exact path="/settings" component={SettingsPage} />
            </AppLayout>
            <Route
              component={(
                  () => (
                    <Result
                      status="404"
                      title="404"
                      subTitle="Sorry, the page you visited does not exist."
                      extra={<Button type="primary"><Link to="/">Back Home</Link></Button>}
                    />
                  )
                )}
            />
          </Switch>
        )}
    </>
  );
};

export default App;

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Row, Col, Spin, Typography } from "antd";
import { useRequest } from "ahooks";

import Chart from "../../Common/Chart";
import { selectors } from "../../App/slice";
import sensors from "../../../repos/sensors";

const MeasurementSelectorInflux = (props) => {
  const { mac, start, end, pollingInterval, nickname, aggregateInterval } =
    props;
  const [keys] = React.useState(["co2_1", "co2_2", "temperature", "humidity"]);
  const { units, precisions } = useSelector(selectors.makeSelectKeys());
  const [series, setSeries] = React.useState([]);

  const {
    data,
    loading,
    run,
    cancel,
    // eslint-disable-next-line no-shadow,
  } = useRequest(
    () => sensors.fetchInflux(mac, start, end, aggregateInterval),
    {
      manual: true,
    }
  );

  useEffect(() => {
    if (data) {
      setSeries(data);
    }
  }, [data]);

  React.useEffect(() => {
    run(mac, start, end, aggregateInterval);
    const intervalId = setInterval(
      () => {
        run(mac, start, end, aggregateInterval);
      },
      pollingInterval,
      aggregateInterval
    );
    return () => {
      cancel();
      clearInterval(intervalId);
    };
  }, [run, cancel, mac, start, end, pollingInterval, aggregateInterval]);

  const yFormatter = (key, v) => {
    const fractionDigits = precisions[key] || 1;
    const unit = units[key] || "";
    return `${parseFloat(v).toFixed(fractionDigits)} ${unit}`;
  };

  const xaxis = React.useMemo(
    () => ({
      type: "datetime",
      tooltip: { enabled: true },
    }),
    []
  );

  return keys.length > 0 ? (
    <>
      <Row align="middle">
        <Col flex="auto">
          <Typography.Title level={4}>
            {nickname} {mac}
          </Typography.Title>
        </Col>
        <Col>
          <Spin spinning={loading} />
        </Col>
      </Row>
      <Spin spinning={!data && loading}>
        <Chart
          id={keys.join(",")}
          series={series}
          yFormatter={yFormatter}
          xaxis={xaxis}
          animations={{ enabled: false }}
          start={start}
          end={end}
          multiple
        />
      </Spin>
    </>
  ) : null;
};

MeasurementSelectorInflux.defaultProps = {
  pollingInterval: undefined,
};

MeasurementSelectorInflux.propTypes = {
  mac: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  pollingInterval: PropTypes.number,
  nickname: PropTypes.string.isRequired,
  aggregateInterval: PropTypes.string.isRequired,
};

export default MeasurementSelectorInflux;

import React from "react";
import PropTypes from "prop-types";
import { Radio, Typography } from "antd";

const axisTypes = ["Multiple axis", "Single axis"];

const AxisTypeSelector = (props) => {
  const { value, onChange } = props;
  return (
    <>
      <Typography.Text>Axis type: </Typography.Text>
      <Radio.Group value={value} onChange={onChange} size="small">
        {axisTypes.map((_axisType) => (
          <Radio.Button key={_axisType} value={_axisType}>
            {_axisType}
          </Radio.Button>
        ))}
      </Radio.Group>
    </>
  );
};

AxisTypeSelector.defaultProps = {
  value: axisTypes[0],
};

AxisTypeSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default AxisTypeSelector;

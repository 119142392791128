import React, { useEffect, useState } from "react";
// import PropTypes from 'prop-types';
import { useRequest } from "ahooks";
import { Helmet } from "react-helmet";

import { Card, Col, Row, Select, Spin, Form, Segmented } from "antd";

import moment from "moment";
import MeasurementSelector from "./MeasurementSelector";

import deviceModel from "../DevicesPage/model";
import DashboardDatePicker from "./DashboardDatePicker";
import MeasurementSelectorInflux from "./MeasurementSelectorInflux";

const DashboardPage = () => {
  const [selectedDateRange, setSelectedDateRange] = React.useState([
    moment().subtract(1, "hour"),
    moment(),
  ]);

  const [active, setActive] = useState("Realtime");
  const [pollingInterval, setPollingInterval] = useState(30 * 1000);
  const [aggregateInterval, setAggregateInterval] = useState(null);
  const [period, setPeriod] = useState(3600000);
  const [start, end] = selectedDateRange;

  const [startTimeInISO, setStartTimeInISO] = useState();
  const [endTimeInISO, setEndTimeInISO] = useState();

  const { data, loading } = useRequest(() =>
    deviceModel.fetchDevices({ current: 1, pageSize: 6 })
  );

  useEffect(() => {
    setStartTimeInISO(
      moment(start, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    );
    setEndTimeInISO(
      moment(end, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DDTHH:mm:ss.SSS[Z]")
    );
  }, [start, end]);

  // useEffect(() => {
  //   setSessionIds(tableProps.dataSource.map((session) => session.id));
  // }, [tableProps.dataSource]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     refresh();
  //   }, 20000); // Refresh every 10 seconds

  //   return () => clearInterval(intervalId);
  // }, [refresh]);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: "100%" }}>
        <Spin size="large" />
      </Row>
    );
  }

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
        <meta name="description" content="Dashboard" />
      </Helmet>

      <Row justify="space-between">
        <Segmented
          options={["Realtime", "Influx"]}
          onChange={(value) => {
            setActive(value); // string
          }}
        />
        <Form layout="inline">
          {active === "Influx" && (
            <Form.Item label="Aggregate Interval">
              <Select
                value={aggregateInterval}
                style={{ width: 120 }}
                onChange={(value) => setAggregateInterval(value)}
                options={[
                  { value: null, label: "None" },
                  { value: "1h", label: "1 Hour" },
                  { value: "3h", label: "3 Hours" },
                  { value: "6h", label: "6 Hours" },
                  { value: "12h", label: "12 Hours" },
                  { value: "1d", label: "1 Day" },
                ]}
              />
            </Form.Item>
          )}
          <Form.Item label="Refresh Interval">
            <Select
              value={pollingInterval}
              style={{ width: 120 }}
              onChange={(value) => setPollingInterval(value)}
              options={[
                { value: 0, label: "None" },
                { value: 5 * 1000, label: "5s" },
                { value: 10 * 1000, label: "10s" },
                { value: 30 * 1000, label: "30s" },
                { value: 60 * 1000, label: "1m" },
                { value: 30 * 10000, label: "5m" },
                { value: 90 * 10000, label: "15m" },
              ]}
            />
          </Form.Item>

          <DashboardDatePicker
            selectedDateRange={selectedDateRange}
            onSelectDateRange={(range) => {
              setSelectedDateRange(range);
            }}
          />
        </Form>
      </Row>

      {active === "Realtime" ? (
        <>
          <Row gutter={[16, 16]} wrap style={{ marginTop: 8 }}>
            {data &&
              data.list.map((item) => (
                <Col span={12} key={item.macAddress}>
                  <Card size="small">
                    <MeasurementSelector
                      mac={item.macAddress}
                      start={startTimeInISO}
                      end={endTimeInISO}
                      pollingInterval={pollingInterval}
                      nickname={item.nickname}
                    />
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      ) : (
        <>
          <Row gutter={[16, 16]} wrap style={{ marginTop: 8 }}>
            {data &&
              data.list.map((item) => (
                <Col span={12} key={item.macAddress}>
                  <Card size="small">
                    <MeasurementSelectorInflux
                      mac={item.macAddress}
                      start={startTimeInISO}
                      end={endTimeInISO}
                      pollingInterval={pollingInterval}
                      nickname={item.nickname}
                      aggregateInterval={aggregateInterval}
                    />
                  </Card>
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  );
};
DashboardPage.propTypes = {};
export default DashboardPage;

import React from "react";
import PropTypes from "prop-types";
import PhoneNumber from "awesome-phonenumber";
import moment from "moment";

import {
  AutoComplete, Avatar, Button, Divider, Form, Input, Row, Select,
} from "antd";
import countries from "../../constants/countries";
import { AuthContext } from "../Auth/AuthContextProvider";

const today = new Date();
const years = [...Array(100).keys()].map((e) => today.getFullYear() - 100 + e);
const months = [...Array(12).keys()].map((e) => e + 1);
const monthNames = moment.monthsShort();
const options = countries.map((c) => ({ value: `${c.emoji} ${c.name}`, label: `${c.emoji} ${c.name}`, code: c.code }));

function UserForm({
  genders,
  fitnessLevels,
  bodyTypes,
  medicalConditions,
  units,
  onSubmit,
  defaultValues,
}) {
  const countryOption = options.find((o) => o.code === defaultValues.country.code);
  const { currentUser } = React.useContext(AuthContext);
  const [photoUrl, setPhotoUrl] = React.useState(currentUser.photoURL);
  const [code, setCode] = React.useState(countryOption.code);
  const [unit, setUnit] = React.useState(units.find((ref) => ref.id === defaultValues.unitRefId));
  const [form] = Form.useForm();

  const imageUploadHandler = ({ target }) => {
    if (target.files && target.files.length > 0) {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(target.files[0]);
      fileReader.onload = (e) => {
        setPhotoUrl(e.target.result);
      };
    }
  };

  const onSubmitHandler = (data) => {
    const _user = {
      ...data,
      id: currentUser.uid,
      uid: currentUser.uid,
      country: code,
      phone: new PhoneNumber(data.phone, code).getNumber("e164"),
      photoUrl: photoUrl
        ? photoUrl.replace(/^data:image\/[a-z]+;base64,/, "")
        : null,
    };
    onSubmit(_user);
  };

  return (
    <>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 8 }}
        layout="horizontal"
        form={form}
        initialValues={{
          ...defaultValues,
          country: countryOption ? countryOption.value : defaultValues.country,
          phone: defaultValues.phone
            ? new PhoneNumber(defaultValues.phone, countryOption.code).getNumber("significant")
            : defaultValues.phone,
        }}
        onFinish={onSubmitHandler}
      >
        <Row justify="center">
          <input
            style={{ display: "none" }}
            accept="image/*"
            id="icon-button-file"
            type="file"
            onChange={imageUploadHandler}
          />
          <label htmlFor="icon-button-file" style={{ cursor: "pointer" }}>
            <Avatar src={photoUrl} size={160} />
          </label>
        </Row>
        <Divider />
        <Form.Item label="Email" name="email">
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Input placeholder="Enter your name" />
        </Form.Item>
        <Form.Item
          name="country"
          label="Country"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <AutoComplete
            options={options}
            filterOption={(inputValue, option) => option.label.toUpperCase().includes(
              inputValue.toString().toUpperCase(),
            )}
            onSelect={(value) => {
              setCode(options.find((o) => o.value === value).code);
            }}
          >
            <Input.Search placeholder="Select your country" />
          </AutoComplete>
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input
            placeholder={code && PhoneNumber.getExample(code).getNumber("significant")}
            addonBefore={`+${PhoneNumber.getCountryCodeForRegionCode(code)}`}
          />
        </Form.Item>
        <Form.Item
          label="Gender"
          name="genderRefId"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select>
            {genders.map((ref) => (
              <Select.Option key={ref.id} value={ref.id}>
                {ref.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Birth Year"
          name="birthYear"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select>
            {years.map((year) => <Select.Option key={year} value={year}>{year}</Select.Option>)}
          </Select>
        </Form.Item>
        <Form.Item
          label="Birth Month"
          name="birthMonth"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select>
            {months.map((month) => (
              <Select.Option key={month} value={month}>
                {monthNames[month - 1]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Fitness Level"
          name="fitnessLevelRefId"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select>
            {fitnessLevels.map((ref) => (
              <Select.Option key={ref.id} value={ref.id}>
                {ref.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Body Type"
          name="bodyTypeRefId"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select>
            {bodyTypes.map((ref) => (
              <Select.Option key={ref.id} value={ref.id}>
                {ref.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Medical Condition"
          name="medicalConditionRefId"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select>
            {medicalConditions.map((ref) => (
              <Select.Option key={ref.id} value={ref.id}>
                {ref.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Others" name="others">
          <Input.TextArea rows={3} placeholder="Any other conditions?" />
        </Form.Item>
        <Form.Item
          label="Measurement Units"
          name="unitRefId"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Select onChange={(value) => {
            setUnit(units.find((ref) => ref.id === value));
          }}
          >
            {units.map((ref) => (
              <Select.Option key={ref.id} value={ref.id}>
                {ref.value}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Height"
          name="height"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Input
            placeholder="Enter your height"
            addonBefore={
              unit && unit.value === "Imperial"
                ? "ft"
                : "cm"
            }
          />
        </Form.Item>
        <Form.Item
          label="Weight"
          name="weight"
          hasFeedback
          rules={[{ required: true, message: " " }]}
        >
          <Input
            placeholder="Enter your weight"
            addonBefore={
              unit && unit.value === "Imperial"
                ? "lb"
                : "kg"
            }
          />
        </Form.Item>
        <Form.Item wrapperCol={{
          xs: { span: 24, offset: 0 },
          sm: { span: 16, offset: 8 },
        }}
        >
          <Button type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </>

  );
}

UserForm.propTypes = {
  genders: PropTypes.array.isRequired,
  fitnessLevels: PropTypes.array.isRequired,
  bodyTypes: PropTypes.array.isRequired,
  medicalConditions: PropTypes.array.isRequired,
  units: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultValues: PropTypes.object.isRequired,
};

export default UserForm;

import React from "react";
import PropTypes from "prop-types";
import {
  Col, DatePicker, Form, Row,
} from "antd";
import moment from "moment";
import _ from "lodash";

const DashboardDatePicker = ({ selectedDateRange, onSelectDateRange }) => (
  <Form layout="horizontal" labelCol={{ flex: "0 0 100px" }}>
    <Row gutter={24}>
      <Col>
        <Form.Item label="Date range">
          <DatePicker.RangePicker
            allowClear={false}
            showTime
            style={{ width: "100%" }}
            ranges={{
              "1 hour": [moment().subtract(1, "hour"), moment()],
              "6 hours": [moment().subtract(6, "hours"), moment()],
              "24 hours": [moment().subtract(24, "hours"), moment()],
              "7 days": [moment().subtract(7, "days"), moment()],
              "30 days": [moment().subtract(30, "days"), moment()],
              "90 days": [moment().subtract(90, "days"), moment()],
            }}
            value={selectedDateRange}
            onChange={onSelectDateRange}
          />
        </Form.Item>
      </Col>
    </Row>
  </Form>
);

DashboardDatePicker.propTypes = {
  selectedDateRange: PropTypes.array.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
};

export default DashboardDatePicker;

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Table,
  Typography,
  Space,
  Button,
  Modal,
  Form,
  Input,
  message,
} from "antd";
import moment from "moment";
import { AuthContext } from "../../Auth/AuthContextProvider";
import model from "../model";

const DevicesTable = (props) => {
  const {
    devices,
    loading,
    pagination,
    onRowSelectionChange,
    onTableChange,
    refresh,
  } = props;

  const [open, setOpen] = React.useState(false);
  const { currentUser } = React.useContext(AuthContext);

  const [form] = Form.useForm();
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [saving, setSaving] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [deviceToDelete, setDeviceToDelete] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);

  // Reset form when modal is closed
  React.useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  const handleDeleteDevice = async (macAddress) => {
    try {
      setOpenModal(false);
      setIsDeleting(true);
      await model.deleteDevice(macAddress);
      refresh();
    } catch (err) {
      message.error("Could not delete device");
    } finally {
      setIsDeleting(false);
    }
  };

  const showModal = () => {
    setOpenModal(true);
  };
  const handleOk = (macAddress) => {
    handleDeleteDevice(macAddress);
  };
  const handleCancel = () => {
    setOpenModal(false);
  };

  const onSubmitHandler = async (data) => {
    setSaving(true);
    try {
      const device = {
        macAddress: data.macAddress,
        nickname: data.nickname,
        userId: currentUser.uid,
      };
      if (isNew) {
        await model.createDevice(device);
        message.success("Device added successfully");
      } else if (isEdit) {
        await model.updateDevice(device);
        message.success("Device updated successfully");
      }
      refresh();
    } catch (err) {
      message.error(err);
    } finally {
      setSaving(false);
      setOpen(false);
      setIsEdit(false);
      setIsNew(false);
    }
  };

  return (
    <>
      <Space style={{ marginBottom: 16 }} align="end">
        <Button
          type="primary"
          onClick={() => {
            setIsNew(true);
            setIsEdit(false);
            setOpen(true);
          }}
        >
          + Add Device
        </Button>
      </Space>
      <Modal
        open={open}
        okText="Save"
        onOk={() => {
          form.submit();
        }}
        destroyOnClose
        onCancel={() => {
          setOpen(false);
        }}
        title="Save Device"
        confirmLoading={saving}
      >
        <Form
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          form={form}
          initialValues={{}}
          onFinish={onSubmitHandler}
        >
          <Form.Item
            label="MAC Address"
            name="macAddress"
            hasFeedback
            rules={[{ required: true, message: " " }]}
          >
            <Input placeholder="MAC Address" disabled={isEdit} />
          </Form.Item>
          <Form.Item
            label="Nickname"
            name="nickname"
            hasFeedback
            rules={[{ required: true, message: " " }]}
          >
            <Input placeholder="Enter a nickname for this device" />
          </Form.Item>
        </Form>
      </Modal>
      <Table
        sortDirections={["ascend", "descend"]}
        dataSource={devices}
        size="small"
        columns={[
          {
            dataIndex: "macAddress",
            title: "MAC Address",
            render: (value, record) => (
              <Typography.Link
                onClick={() => {
                  form.setFieldsValue(record);
                  setIsNew(false);
                  setIsEdit(true);
                  setOpen(true);
                }}
              >
                {value}
              </Typography.Link>
            ),
          },
          {
            dataIndex: "nickname",
            title: "Nickname",
          },
          {
            dataIndex: "createdAt",
            title: "Created At",
            render: (value) => moment(value).utc().format("lll"),
            sorter: true,
          },
          {
            dataIndex: "updatedAt",
            title: "Updated At",
            render: (value) => moment(value).utc().format("lll"),
            sorter: true,
          },
          {
            dataIndex: "delete",
            width: "15%",
            render: (_, record) => (
              <Button
                type="default"
                onClick={() => {
                  showModal(record);
                  setDeviceToDelete(record);
                }}
                loading={deviceToDelete.macAddress === record.macAddress && isDeleting}
              >
                Delete
              </Button>
            ),
          },
        ]}
        rowKey="id"
        rowSelection={{
          type: "checkbox",
          onChange: onRowSelectionChange,
          getCheckboxProps: (record) => ({ name: record.name }),
        }}
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
        scroll={{ x: 1200 }}
      />

      <Modal
        title="Delete Device"
        open={openModal}
        onOk={() => handleOk(deviceToDelete.macAddress)}
        centered
        onCancel={handleCancel}
      >
        <p>
          Are you sure you want to delete the device "
          {deviceToDelete.nickname}
          "
        </p>
      </Modal>
    </>
  );
};

DevicesTable.defaultProps = {};

DevicesTable.propTypes = {
  devices: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  onRowSelectionChange: PropTypes.func.isRequired,
  onTableChange: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
};

export default DevicesTable;

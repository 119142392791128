import { getRequest, postRequest } from "../../services/request";

const parseFilter = (_filters = {}, location) => {
  const filter = {};
  for (let i = 0; i < Object.keys(_filters).length; i += 1) {
    const key = Object.keys(_filters)[i];
    if (_filters[key]) {
      filter[key] = _filters[key];
    }
  }

  const params = Object.fromEntries(new URLSearchParams(location.search.replace("?", "")));
  for (let i = 0; i < Object.keys(params).length; i += 1) {
    const key = Object.keys(params)[i];
    if (params[key]) {
      filter[key] = params[key];
    }
  }

  return filter;
};

const fetchSchedules = async ({
  current, pageSize, sorter = { order: "descend", field: "created_at" }, filters,
}, _location) => {
  const filter = parseFilter(filters, _location);
  const params = new URLSearchParams({
    filter: JSON.stringify(filter),
    limit: pageSize,
    offset: (current - 1) * pageSize,
    order: sorter.order === "ascend" ? "ASC" : "DESC",
    orderBy: sorter.field || "created_at",
  });

  const { data } = await getRequest(`/schedules?${params}`);
  return { list: data };
};

const saveToAWS = async(schedulePayload) => {
  const data = await postRequest('/schedules/saveToAWS', schedulePayload);
  console.log('SaveToAWS::::Response:::', data);
  return data;
} 

export default {
  fetchSchedules,
  saveToAWS
};

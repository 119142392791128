import { DownOutlined } from "@ant-design/icons";
import {
  Badge, Dropdown,
  Modal,
  Space, Table, Typography,
  notification
} from "antd";
import React, { useMemo, useState } from "react";

import { useRequest } from "ahooks";

import model from "../../DevicesPage/model";
import scheduleModel from "../model";

const Context = React.createContext({
  name: 'Default',
});

const ScheduleTable = ({
  schedules,
  loading,
  pagination,
  onIdClick,
  onTableChange,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deviceSelected, setDeviceSelected] = useState("");
  const [scheduleSelected, setScheduleSelected] = useState(0);

  const { data, loading: fetchLoading } = useRequest(() => model.fetchDevices({ current: 1, pageSize: 1000 }));

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = async () => {
    const schedule = schedules.filter((schedule) => schedule.id === scheduleSelected)
    let scheduleJson = [];
    
    schedule[0].timeslots.forEach(timeslot => {
      let slotValue = {};
      let slotMinute = {};

      slotValue.value = parseInt(timeslot.value);
      slotValue.units = "ppm";
      slotMinute.value = parseInt(timeslot.minute);
      slotMinute.units = "minutes";
      scheduleJson.push({"setpoint": slotValue, "period": slotMinute});
    });

    const payload = {
      mac_address: deviceSelected,
      schedule: JSON.stringify(scheduleJson)
    }
    const response = await scheduleModel.saveToAWS(payload);

    console.log(response)

    if (response.success) {
      notification.success({
        message: `Notification`,
        description: <Context.Consumer>{({ name }) => `Pushed to the AWS successfully!`}</Context.Consumer>,
        placement: 'topRight',
      });
      setIsModalOpen(false);
      setScheduleSelected(0)
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items = useMemo(
    () => data?.list.map((item) => ({
      label: item.nickname,
      key: item.macAddress,
      onClick: () => {
        setDeviceSelected(item.macAddress);
        showModal();
      },
    })),
    [data],
  );

  return (
    <>
      <Table
        sortDirections={["ascend", "descend"]}
        dataSource={schedules}
        size="small"
        columns={[
          {
            dataIndex: "name",
            title: "Name",
            sorter: true,
            width: "20%",
            render: (value, record) => (
              <Typography.Link
                onClick={() => {
                  onIdClick(record.id);
                }}
              >
                {value}
              </Typography.Link>
            ),
          },
          {
            dataIndex: "description",
            title: "Description",
            width: "55%",
          },
          {
            dataIndex: "is_received",
            title: "Status",
            width: "10%",
            render: (value, record) => (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Badge status={value ? "success" : "error"} size="large" />
              </div>
            ),
          },
          {
            dataIndex: "push",
            width: "15%",
            render: (value, record) => {
              return (
                <Dropdown
                  menu={{ items }}
                  trigger={["click"]}
                  onClick={() => setScheduleSelected(record.id)}
                >
                  <Typography.Link>
                    <Space>
                      Push schedule
                      <DownOutlined />
                    </Space>
                  </Typography.Link>
                </Dropdown>
              );
            },
          },
        ]}
        rowKey="id"
        loading={loading}
        pagination={pagination}
        onChange={onTableChange}
        scroll={{ x: 400 }}
      />

      <Modal
        title="Push Schedule"
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
      >
        <p>
          Are you sure you want to push the schedule [
          {scheduleSelected}
          ] to
          device [
          {deviceSelected}
          ]
        </p>
      </Modal>
    </>
  );
};
export default ScheduleTable;

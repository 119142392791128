export const getMaxKey = (data, schedule_key = "") => {
  // let temp = data.filter(e => e.schedule_key === schedule_key);

  if (data.length === 0) return 1;

  let maxKey = null;
  data.forEach((item) => {
    if (!maxKey || item.key > maxKey) {
      maxKey = item.key;
    }
  });

  return maxKey + 1;
};

import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router";
import { Helmet } from "react-helmet";
import {
  Card, Col, Row, message, Divider, Button,
} from "antd";

import model from "./model";

import { getMaxKey } from "./util";

import ListSort from "./ListSort";
import AddTimeSlots from "./AddTimeSlot";
import Schedule from "./Schedule";

const SchedulesPage = () => {
  const { id } = useParams();
  const history = useHistory();

  const [dataSource, setDataSource] = useState([]);
  const [ScheduleName, setScheduleName] = useState({
    id: "new",
    isRepeat: false,
  });
  const [ScheduleData, setScheduleData] = useState([]);

  useEffect(() => {
    if (id !== "new") {
      onLoad();
    }
  }, [id]);

  const onLoad = async () => {
    await model.fetchSchedule(id)
      .then((res) => {
        setScheduleName(res);
        setScheduleData(res);
        if (res.timeslots?.length > 0) {
          setDataSource(res.timeslots);
        }
      }).catch(() => {
        message.error("Load Data Failed.");
      });
  };

  const onAddTimeSlot = async (newSource) => {
    try {
      newSource.key = getMaxKey(dataSource);

      setDataSource([...dataSource, newSource]);
    } catch (err) {
      message.error(err.message);
    }
  };

  const onSubmitScheduleData = async () => {
    const newData = ScheduleData;
    console.log(ScheduleData);
    newData.isRepeat = !!newData.isRepeat;
    newData.timeslots = JSON.stringify(dataSource);

    if (newData.id === "new") {
      delete newData.id;
      await model.createScheduleName(newData)
        .then((res) => {
          message.success("Created Successfully.");
          history.push(`/schedules/${res.id}`);
        }).catch(() => {
          message.error("Failed Create New Schedule.");
        });
    } else {
      await model.updateSchedule(newData)
        .then(() => {
          setScheduleData(newData);
          message.success("Updated Successfully.");
        }).catch(() => {
          message.error("Failed Update Schedule.");
        });
    }
  };

  return (
    <>
      <Helmet>
        <title>Schedule</title>
        <meta name="description" content="Description of SchedulesPage" />
      </Helmet>
      <Card>
        <Row gutter={24}>
          <Col span={24}>
            <Schedule
              ScheduleName={ScheduleName}
              setScheduleData={setScheduleData}
            />
          </Col>
        </Row>
        <Divider />
        <Row gutter={24}>
          <Col xs={24} lg={8}>
            <AddTimeSlots
              onSubmit={onAddTimeSlot}
            />
          </Col>
          <Col xs={24} lg={16}>
            <ListSort
              setDataSource={setDataSource}
              dataSource={dataSource}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button type="primary" onClick={() => onSubmitScheduleData()}>Save</Button>
            </div>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default SchedulesPage;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card, Col, message, Row, Typography,
} from "antd";

import { AuthContext } from "../AuthContextProvider";
import { selectors as appSelectors } from "../../App/slice";
import { SECONDARY_COLOR } from "../../../constants";

const AuthActionPage = () => {
  const loading = useSelector(appSelectors.makeSelectLoading());
  const { applyActionCode, verifyPasswordResetCode } = React.useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  let content;

  const handleResetPassword = async (actionCode) => {
    try {
      const email = await verifyPasswordResetCode(actionCode);
      history.push("/reset", { code: actionCode, email });
    } catch (err) {
      console.log(err);
      message.error("Code is invalid or expired");
    }
  };

  const handleVerifyEmail = async (actionCode) => {
    try {
      await applyActionCode(actionCode);
      message.success("Email address verified");
      history.push("/onboard");
    } catch (err) {
      console.log(err);
      message.error("Code is invalid or expired");
      history.push("/verify");
    }
  };

  const params = new URLSearchParams(location.search);
  const mode = params.get("mode");
  const actionCode = params.get("oobCode");
  // const continueUrl = params.get('continueUrl');
  // const lang = params.get('lang') || 'en';
  switch (mode) {
    case "resetPassword":
      handleResetPassword(actionCode);
      break;
    case "verifyEmail":
      handleVerifyEmail(actionCode);
      content = <Typography.Text>Verifying email address...</Typography.Text>;
      break;
    default:
        // Error: invalid mode.
  }

  if (loading) {
    return (
      <Helmet>
        <title>Auth Action Page</title>
        <meta name="description" content="Auth Action Page" />
      </Helmet>
    );
  }

  return (
    <div>
      <Helmet>
        <title>Auth Action Page</title>
        <meta name="description" content="Auth Action Page" />
      </Helmet>
      <Row justify="center" align="middle" style={{ height: "100vh", background: SECONDARY_COLOR }}>
        <Col xxl={5} xl={6} lg={8} md={12} sm={12} xs={20}>
          <Card>
            <Typography>
              <Typography.Title>Register</Typography.Title>
            </Typography>
            {content}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

AuthActionPage.propTypes = {};

export default AuthActionPage;

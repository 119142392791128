import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Row, Col, Spin, Typography } from "antd";
import { useRequest } from "ahooks";
import moment from "moment";
import Chart from "../../Common/Chart";
import { selectors } from "../../App/slice";
import sensors from "../../../repos/sensors";

const MeasurementSelector = (props) => {
  const { mac, start, end, pollingInterval, nickname } = props;
  const [keys] = React.useState(["co2_1", "co2_2", "temperature", "humidity"]);
  const { units, precisions } = useSelector(selectors.makeSelectKeys());
  const [series, setSeries] = React.useState([]);

  const {
    data,
    loading,
    run,
    cancel,
    // eslint-disable-next-line no-shadow
  } = useRequest(() => sensors.fetch(mac, start, end), {
    manual: true,
  });

  React.useEffect(() => {
    run(mac, start, end);
    const intervalId = setInterval(() => {
      run(mac, start, end);
    }, pollingInterval);
    return () => {
      cancel();
      clearInterval(intervalId);
    };
  }, [run, cancel, mac, start, end, pollingInterval]);

  React.useEffect(() => {
    if (data) {
      setSeries(
        keys.map((key) => ({
          name: key,
          data: data.map((d) => [
            moment(Date.parse(d.date))
              .add(d.hour, "hour")
              .add(d.minute, "minute")
              .valueOf(),
            d[key],
          ]),
        }))
      );
    }
  }, [data, keys]);

  const yFormatter = (key, v) => {
    const fractionDigits = precisions[key] || 1;
    const unit = units[key] || "";
    return `${parseFloat(v).toFixed(fractionDigits)} ${unit}`;
  };

  const xaxis = React.useMemo(
    () => ({
      type: "datetime",
      tooltip: { enabled: true },
    }),
    []
  );

  return keys.length > 0 ? (
    <>
      <Row align="middle">
        <Col flex="auto">
          <Typography.Title level={4}>
            {nickname} {mac}
          </Typography.Title>
        </Col>
        <Col>
          <Spin spinning={loading} />
        </Col>
      </Row>
      <Spin spinning={!data && loading}>
        <Chart
          id={keys.join(",")}
          series={series}
          yFormatter={yFormatter}
          xaxis={xaxis}
          animations={{ enabled: false }}
          multiple
          start={start}
          end={end}
        />
      </Spin>
    </>
  ) : null;
};

MeasurementSelector.defaultProps = {
  pollingInterval: undefined,
};

MeasurementSelector.propTypes = {
  mac: PropTypes.string.isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  pollingInterval: PropTypes.number,
  nickname: PropTypes.string.isRequired,
};

export default MeasurementSelector;

import { Space, Typography } from "antd";
import React from "react";
import { useLocation } from "react-router";

import { useAntdTable } from "ahooks";
import { PAGE_SIZE } from "../../constants";
import model from "./model";
import DevicesTable from "./DevicesTable";

const DevicesPage = () => {
  const location = useLocation();

  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);

  const { tableProps, refresh } = useAntdTable(
    (params) => model.fetchDevices(params, location),
    { defaultPageSize: PAGE_SIZE, refreshDeps: [location] },
  );

  return (
    <>
      <Space style={{ marginBottom: "16px" }}>
        <Typography>
          <Typography.Text>
            {selectedRowKeys.length
              ? `${selectedRowKeys.length} row(s) selected`
              : ""}
          </Typography.Text>
        </Typography>
      </Space>

      <DevicesTable
        devices={tableProps.dataSource}
        loading={tableProps.loading}
        pagination={tableProps.pagination}
        refresh={refresh}
        onRowSelectionChange={setSelectedRowKeys}
        onTableChange={tableProps.onChange}
      />
    </>
  );
};

export default DevicesPage;

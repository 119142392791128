import { Breadcrumb } from "antd";
import React from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

const AppBreadcrumb = () => {
  const location = useLocation();
  const [current, setCurrent] = React.useState(location.pathname);

  React.useEffect(() => {
    setCurrent(location.pathname);
  }, [location]);

  return (
    <Breadcrumb style={{ margin: "16px 0" }}>
      <Breadcrumb.Item><Link to="/">Home</Link></Breadcrumb.Item>
      {current !== "/" && current.split("/").slice(1)
        .map(
          (path, index) => ((index < current.split("/").length - 1)
            ? (
              <Breadcrumb.Item key={path}>
                <Link to={`/${path}`}>
                  {path.split("-").map((p) => p.charAt(0).toUpperCase() + p.slice(1)).join(" ")}
                </Link>
              </Breadcrumb.Item>
            )
            : <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>),
        )}
    </Breadcrumb>
  );
};

export default AppBreadcrumb;

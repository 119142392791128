import React from "react";
import PropTypes from "prop-types";
import { Radio, Typography } from "antd";

const intervals = ["NA", "100ms", "250ms", "500ms", "1s", "5s", "10s", "30s", "1m", "5m"];

const IntervalSelector = (props) => {
  const { value, onChange } = props;
  return (
    <>
      <Typography.Text>Interval: </Typography.Text>
      <Radio.Group value={value} onChange={onChange} size="small">
        {intervals.map((i) => <Radio.Button key={i} value={i}>{i}</Radio.Button>)}
      </Radio.Group>
    </>
  );
};

IntervalSelector.defaultProps = {
  value: intervals[3],
};

IntervalSelector.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default IntervalSelector;

import React from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  Affix, Card, Empty, PageHeader, Spin,
} from "antd";
import { useRequest } from "ahooks";
import TrendSelector from "./TrendSelector";
import { actions, selectors as appSelectors } from "../App/slice";
import trendsRepo from "../../repos/measurements";
import TrendsSearchCriteria from "./SearchCriteria";
import preferencesRepo from "../../repos/preferences";

const TrendsPage = () => {
  const user = useSelector(appSelectors.makeSelectUser());

  const [selectedSharedUserId, setSelectedSharedUserId] = React.useState(user.id);
  // eslint-disable-next-line max-len
  // const [selectedSharedUserId, setSelectedSharedUserId] = React.useState('TdyjwRBfQXODA6aqFMv8NqK2Ubr1');

  const [selectedDateRange, setSelectedDateRange] = React.useState([moment().subtract(30, "days"), moment()]);
  const [selectedTags, setSelectedTags] = React.useState([]);
  const [interval, setInterval] = React.useState("1d");
  const [selectedDuration, setSelectedDuration] = React.useState();
  const dispatch = useDispatch();

  const { data: trends, loading: trendsLoading } = useRequest(
    () => {
      const [start, end] = selectedDateRange;

      return trendsRepo.fetchTrends(
        selectedTags,
        [moment(start).utc().startOf("day"), moment(end).utc().endOf("day")],
        selectedSharedUserId || user.id,
        interval,
        selectedDuration,
      );
    },
    {
      refreshDeps: [selectedTags, selectedDateRange, selectedSharedUserId, user.id, interval,
        selectedDuration],
      formatResult: ({ trends: _trends }) => _trends,
    },
  );

  const { data: userPreferences, loading: prefLoading, run: fetchUserPreferences } = useRequest(
    () => preferencesRepo.fetch(user),
    { cacheKey: "userPreferences", refreshDeps: [user] },
  );

  const { loading: updatePrefLoading, run: runUpdatePref } = useRequest(
    (trendsKeys) => {
      if (userPreferences === undefined) {
        return preferencesRepo.create(user.id, { ...userPreferences, trendsKeys });
      }
      return preferencesRepo.update(user.id, { ...userPreferences, trendsKeys });
    },
    {
      manual: true,
      onSuccess: fetchUserPreferences,
    },
  );

  const series = React.useMemo(
    () => (
      {
        data: trends
          ? trends.filter((e) => e.BVTA_ACP_TIM)
            .map((e) => [moment(e.time), e.BVTA_ACP_TIM])
          : [],
      }
    ),
    [trends],
  );

  React.useEffect(() => {
    if (trends) {
      const timestamps = trends.map((e) => moment(e.time));
      dispatch(actions.setExtent([Math.min(...timestamps), Math.max(...timestamps)]));
    }
  }, [trends]);

  return (
    <Spin spinning={trendsLoading || prefLoading || updatePrefLoading}>
      <Affix>
        <PageHeader ghost={false} style={{ zIndex: 20 }}>
          <TrendsSearchCriteria
            onSelectUser={(_sharedUserId) => {
              setSelectedSharedUserId(_sharedUserId);
            }}
            selectedUserId={selectedSharedUserId}
            selectedTags={selectedTags}
            onSelectTags={(val) => {
              setSelectedTags(val);
            }}
            selectedDateRange={selectedDateRange}
            onSelectDateRange={(range) => {
              setSelectedDateRange(range);
            }}
            selectedInterval={interval}
            onSelectInterval={(e) => {
              setInterval(e.target.value);
            }}
            selectedDuration={selectedDuration}
            onSelectDuration={(range) => {
              setSelectedDuration(range);
            }}
          />
        </PageHeader>
      </Affix>
      <Card size="small" style={{ margin: "8px 0px" }}>
        {(trends?.length || 0) > 0
          ? (
            <TrendSelector
              data={trends}
              brushSeries={series || []}
              defaultKeys={userPreferences.trendsKeys}
              onSaveChart={runUpdatePref}
            />
          )
          : <Empty style={{ padding: 16 }} />}
      </Card>
    </Spin>
  );
};

export default TrendsPage;

import React from "react";
import PropTypes from "prop-types";
import {
  Avatar, Col, DatePicker, Form, Row, Select, Space, TimePicker,
} from "antd";
import { useRequest } from "ahooks";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import tagsRepo from "../../../repos/tags";
import usersRepo from "../../../repos/users";
import { selectors as appSelectors } from "../../App/slice";
import IntervalSelector from "../IntervalSelector";

const TrendsSearchCriteria = ({
  onSelectUser, selectedUserId,
  selectedTags, onSelectTags,
  selectedDateRange, onSelectDateRange,
  selectedInterval, onSelectInterval,
  selectedDuration, onSelectDuration,
}) => {
  const user = useSelector(appSelectors.makeSelectUser());
  const { data: tags, loading: tagsLoading } = useRequest(
    () => tagsRepo.fetchTags(user),
    { cacheKey: "usertags", refreshDeps: [user] },
  );

  const { data: sharedUsers, loading: sharedUsersLoading } = useRequest(
    () => usersRepo.fetchSharedUsers(user),
    {
      cacheKey: "sharedUsers",
      refreshDeps: [user],
      formatResult: ({ data }) => [
        user,
        ..._.uniqWith(data.map((e) => e.owner), (a, b) => a.id === b.id),
      ],
    },
  );

  return (
    <Form layout="horizontal" labelCol={{ flex: "0 0 100px" }}>
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item label="User">
            <Select
              loading={sharedUsersLoading}
              filterOption={false}
              onSelect={onSelectUser}
              style={{ width: "100%" }}
              value={selectedUserId}
            >
              {sharedUsers?.map((option) => (
                <Select.Option key={option.id} value={option.id}>
                  {option.id ? (
                    <Space direction="horizontal" size="small">
                      <Avatar size="small" src={option.photoUrl}>
                        {!option.photoUrl
                          ? (option.name || option.email).substring(0, 1).toUpperCase()
                          : null}
                      </Avatar>
                      {option.email}
                    </Space>
                  ) : option.email}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Tags">
            <Select
              loading={tagsLoading}
              placeholder="Select tags"
              style={{ width: "100%" }}
              mode="multiple"
              value={selectedTags}
              onChange={onSelectTags}
              onDeselect={(value) => {
                const _tags = selectedTags.filter((tag) => tag !== value);
                onSelectTags(_tags);
              }}
            >
              {tags?.map((e) => <Select.Option key={e.name}>{e.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Date range">
            <DatePicker.RangePicker
              style={{ width: "100%" }}
              ranges={{
                "This Week": [moment().startOf("week"), moment().endOf("week")],
                "Last Week": [moment().startOf("week").subtract(1, "week"), moment().endOf("week").subtract(1, "week")],
                "Last 7 days": [moment().subtract(7, "days"), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().startOf("month").subtract(1, "month"), moment().endOf("month").subtract(1, "month")],
                "Last 30 days": [moment().subtract(30, "days"), moment()],
                "This Quarter": [moment().startOf("quarter"), moment().endOf("quarter")],
                "Last Quarter": [moment().startOf("quarter").subtract(1, "quarter"), moment().endOf("quarter").subtract(1, "quarter")],
                "This Year": [moment().startOf("year"), moment().endOf("year")],
                "Last Year": [moment().startOf("year").subtract(1, "year"), moment().endOf("year").subtract(1, "year")],
              }}
              value={selectedDateRange}
              onChange={onSelectDateRange}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Duration">
            <TimePicker.RangePicker
              format="h a"
              style={{ width: "100%" }}
              // ranges={{
              //   Morning: [
              //     moment().hour(6).minute(0).second(0), moment().hour(12).minute(0).second(0),
              //   ],
              //   Afternoon: [
              //     moment().hour(12).minute(0).second(0), moment().hour(16).minute(0).second(0),
              //   ],
              //   Evening: [
              //     moment().hour(16).minute(0).second(0), moment().hour(21).minute(0).second(0),
              //   ],
              //   Night: [
              //     moment().hour(21).minute(0).second(0), moment().hour(6).minute(0).second(0),
              //   ],
              // }}
              value={selectedDuration}
              onChange={onSelectDuration}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="Interval">
            <IntervalSelector value={selectedInterval} onChange={onSelectInterval} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

TrendsSearchCriteria.propTypes = {
  selectedUserId: PropTypes.string.isRequired,
  onSelectUser: PropTypes.func.isRequired,
  selectedTags: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectTags: PropTypes.func.isRequired,
  selectedDateRange: PropTypes.array.isRequired,
  onSelectDateRange: PropTypes.func.isRequired,
  selectedInterval: PropTypes.string.isRequired,
  onSelectInterval: PropTypes.func.isRequired,
  selectedDuration: PropTypes.array.isRequired,
  onSelectDuration: PropTypes.func.isRequired,
};

export default TrendsSearchCriteria;

import React from "react";
import {
  Button, Form, Input, InputNumber,
} from "antd";

const AddTimeSlots = ({
  onSubmit,
}) => {
  const [form] = Form.useForm();

  const onSubmitHandler = (data) => {
    onSubmit(data);
    form.resetFields(["value", "hour", "minute"]);
  };

  return (
    <Form
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      layout="horizontal"
      form={form}
      onFinish={onSubmitHandler}
    >
      <Form.Item
        label="schedule_key"
        name="schedule_key"
        hidden
      >
        <Input placeholder="Enter name" />
      </Form.Item>
      <Form.Item
        label="CO2%"
        name="value"
        hasFeedback
        rules={[{ required: true, message: " " }]}
      >
        <Input placeholder="Enter your value" />
      </Form.Item>
      <Form.Item
        label="Hour"
        name="hour"
        hasFeedback
        rules={[{ required: true, message: " " }]}
      >
        <Input
          placeholder="Enter your Hour"
        />
      </Form.Item>
      <Form.Item
        label="Minute"
        name="minute"
        hasFeedback
        rules={[{ required: true, message: " " }]}
      >
        <Input
          placeholder="Enter your Minute"
        />
      </Form.Item>
      <Form.Item wrapperCol={{
        xs: { span: 24, offset: 0 },
        sm: { span: 16, offset: 8 },
      }}
      >
        <Button type="primary" htmlType="submit">
          Add
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddTimeSlots;

import React from "react";
import PropTypes from "prop-types";
import { Avatar, Tooltip } from "antd";

const UserAvatar = ({
  photoUrl, name, email, tooltip, size, ...rest
}) => {
  let avatar = (
    <Avatar src={photoUrl} size={size} {...rest}>
      {!photoUrl ? (name || email).substring(0, 1).toUpperCase() : null}
    </Avatar>
  );

  if (tooltip) {
    avatar = <Tooltip title={name || email}>{avatar}</Tooltip>;
  }

  return avatar;
};

UserAvatar.defaultProps = {
  photoUrl: undefined,
  name: undefined,
  tooltip: false,
  size: undefined,
};

UserAvatar.propTypes = {
  photoUrl: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string.isRequired,
  tooltip: PropTypes.bool,
  size: PropTypes.string,
};

export default UserAvatar;

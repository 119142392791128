import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  Button, Checkbox, List, message, Modal, Popconfirm, Row, Spin,
} from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";

import { useSelector } from "react-redux";
import {
  getRequest, postRequest, deleteRequest, putRequest,
} from "../../../services/request";
import UserListItem from "../../Common/UserListItem";
import UserAutoComplete from "../../Common/UserAutoComplete";
import { selectors as appSelectors } from "../../App/slice";

const ShareButton = ({ onSubmit, selectedSessions }) => {
  const user = useSelector(appSelectors.makeSelectUser());

  const [loading, setLoading] = React.useState(false);
  const [toRemove, setToRemove] = React.useState([]);
  const [sessionUsers, setSessionUsers] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [indeterminate, setIndeterminate] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [visible, setVisible] = React.useState(false);

  const init = React.useCallback(() => {
    if (visible && selectedSessions && selectedSessions.length > 0) {
      setLoading(true);
      const params = new URLSearchParams({
        filter: JSON.stringify({ sessionId: selectedSessions.map((s) => s.id) }),
      });
      getRequest(`/session-users?${params}`).then(({ data }) => {
        setSessionUsers(data);
        const _users = data.map((e) => ({ ...e.user, owner: e.owner, withProfile: e.withProfile }));
        setUsers(_.uniqBy(_users, (e) => e.id));
        const all = data.filter((e) => e.withProfile).length === data.length;
        setCheckAll(all);
        setIndeterminate(!all && data.some((e) => e.withProfile));
        setLoading(false);
      });
    }
  }, [selectedSessions, visible]);

  React.useEffect(() => { init(); }, [init]);

  const onCancelHandler = () => { setVisible(false); };

  const onSubmitHandler = async () => {
    try {
      setLoading(true);
      const _users = selected.map((e) => e.key);
      const promises = [];

      // new
      if (_users && _users.length > 0) {
        const newSessionUsers = _.flatMap(
          selectedSessions.map((s) => _users.map((userId) => ({
            sessionId: s.id,
            userId,
            owner: sessionUsers.find((u) => u.owner).userId === userId,
            withProfile: checkAll,
          }))),
        );
        promises.push(...newSessionUsers.map((e) => postRequest("/session-users", e)));
      }

      // existing
      const remainingUsers = users.filter((u) => !toRemove.includes(u.id));
      if (remainingUsers.length > 0) {
        let remainingSessionUsers = _.flatMap(
          selectedSessions.map((s) => remainingUsers.map((u) => ({
            id: sessionUsers.find((e) => e.sessionId === s.id && e.userId === u.id)?.id,
            sessionId: s.id,
            userId: u.id,
            owner: sessionUsers.find((e) => e.owner).userId === u.id,
            withProfile: u.withProfile,
          }))),
        );

        const newSessionUsers = remainingSessionUsers.filter((e) => !e.id);
        remainingSessionUsers = remainingSessionUsers.filter((e) => e.id);

        promises.push(
          ...newSessionUsers.map((e) => postRequest("/session-users", e)),
          ...remainingSessionUsers.map((e) => putRequest(`/session-users/${e.id}`, e)),
        );
      }

      // to remove
      if (toRemove.length > 0) {
        const sessionsUserToRemove = sessionUsers.filter((e) => toRemove.includes(e.userId));
        promises.push(...sessionsUserToRemove.map((e) => deleteRequest(`/session-users/${e.id}`)));
      }

      await Promise.all(promises);
      onSubmit();
      setLoading(false);
      onCancelHandler();
    } catch (err) {
      message.error(err.message);
      init();
    }
  };

  const onRemoveClickHandler = (id) => () => {
    setToRemove((prev) => [...prev, id]);
  };

  const onSelectHandler = (val, { key, value }) => {
    setSelected((prev) => [...prev, { key, value }]);
  };

  const onDeselectHandler = (value) => {
    setSelected(selected.filter((e) => e.value !== value));
  };

  const onCheckAllChangeHandler = (event) => {
    setCheckAll(event.target.checked);
    setIndeterminate(false);
    setUsers(users.map((e) => ({ ...e, withProfile: event.target.checked })));
  };

  const owner = users.find((e) => e.owner);
  const filtered = users.filter((e) => !toRemove.includes(e.id) && !e.owner);
  const canShare = selectedSessions?.length > 0
    && !selectedSessions?.some((s) => s.userId !== user.id);

  return (
    <>
      <Button type="primary" disabled={!canShare} onClick={() => { setVisible(true); }}>
        Share sessions
      </Button>
      <Modal
        width="40%"
        visible={visible}
        onCancel={onCancelHandler}
        maskClosable={false}
        title="Share with people"
        footer={(
          <Row justify="end" align="middle">
            <Button onClick={onCancelHandler}>Cancel</Button>
            <Button type="primary" onClick={onSubmitHandler}>Save</Button>
          </Row>
      )}
        destroyOnClose
      >
        <Spin spinning={loading}>
          <UserAutoComplete
            onSelect={onSelectHandler}
            onDeselect={onDeselectHandler}
          />
          <Checkbox
            onChange={onCheckAllChangeHandler}
            checked={checkAll}
            indeterminate={indeterminate}
            style={{ margin: "16px 0px" }}
          >
            Also share profile information
          </Checkbox>
          <List size="small" style={{ maxHeight: "300px", overflowY: "auto" }}>
            {owner && (
            <UserListItem {...owner} extra={<div style={{ fontStyle: "italic", marginRight: "24px" }}>Owner</div>} />
            )}

            {filtered
              .map((e) => (
                <UserListItem
                  key={e.email}
                  {...e}
                  actions={[
                    <Checkbox
                      checked={e.withProfile}
                      onChange={(event) => {
                        const _users = filtered.map((s) => {
                          if (s.id === e.id) {
                            return { ...s, withProfile: event.target.checked };
                          }
                          return s;
                        });
                        setUsers([owner, ..._users]);
                        const all = _users.filter((u) => u.withProfile).length === _users.length;
                        setCheckAll(all);
                        setIndeterminate(all ? false : _users.some((u) => u.withProfile));
                      }}
                    >
                      With profile
                    </Checkbox>,
                    <Popconfirm
                      title="Are you sure?"
                      onConfirm={onRemoveClickHandler(e.id)}
                    >
                      <Button type="text" danger icon={<CloseCircleOutlined />} />
                    </Popconfirm>,
                  ]}
                />
              ))}
          </List>
        </Spin>
      </Modal>
    </>
  );
};

ShareButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  selectedSessions: PropTypes.array.isRequired,
};

export default ShareButton;

import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import {
  Form, message, Modal, Select, Spin,
} from "antd";

import { Button } from "antd/lib/radio";
import { selectors as appSelectors } from "../../App/slice";
import { putRequest } from "../../../services/request";

const ApplyTagsButton = (props) => {
  const {
    tags, selectedTags, onSubmit, selectedSessions,
  } = props;

  const [form] = Form.useForm();
  const currentUser = useSelector(appSelectors.makeSelectUser());
  const [submitting, setSubmitting] = React.useState(false);
  const [visible, setVisible] = React.useState(false);

  const updateTags = async (_tags) => Promise.all(
    selectedSessions.map((session) => putRequest(
      `/sessions/${session.id}`, { ...session, meta: { tags: _tags } },
    )),
  );

  const onSubmitHandler = async () => {
    if (!form.isFieldTouched("tags")) {
      setVisible(false);
    } else {
      try {
        const { tags: _tags } = await form.validateFields();
        setSubmitting(true);
        await updateTags(_tags.map((tag) => ({ name: tag, userId: currentUser.id })));
        message.success("Tags applied");
      } catch (err) {
        message.error(err.message);
      } finally {
        onSubmit();
        setSubmitting(false);
        setVisible(false);
      }
    }
  };

  return (
    <>
      <Button type="primary" disabled={!selectedSessions.length} onClick={() => { setVisible(true); }}>
        Apply tags
      </Button>
      <Modal
        visible={visible}
        onCancel={() => { setVisible(false); }}
        title="Apply tags to sessions"
        maskClosable={false}
        okText="Apply tags"
        onOk={onSubmitHandler}
      >
        <Spin spinning={submitting}>
          <Form
            form={form}
            name="apply_tags"
            initialValues={{ tags: selectedTags }}
          >
            <Form.Item name="tags">
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Type to select or add tags"
                tokenSeparators={[","]}
              >
                {tags.map((tag) => (
                  <Select.Option key={tag.name} value={tag.name}>
                    {tag.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};

ApplyTagsButton.propTypes = {
  tags: PropTypes.array.isRequired,
  selectedTags: PropTypes.array.isRequired,
  selectedSessions: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ApplyTagsButton;

import { getRequest, postRequest, putRequest } from "../../services/request";

const parseFilter = (_filters = {}, location) => {
  const filter = {};
  for (let i = 0; i < Object.keys(_filters).length; i += 1) {
    const key = Object.keys(_filters)[i];
    if (_filters[key]) {
      filter[key] = _filters[key];
    }
  }

  const params = Object.fromEntries(
    new URLSearchParams(location.search.replace("?", "")),
  );
  for (let i = 0; i < Object.keys(params).length; i += 1) {
    const key = Object.keys(params)[i];
    if (params[key]) {
      filter[key] = params[key];
    }
  }

  return filter;
};

const fetchSessions = async (
  {
    current,
    pageSize,
    sorter = { order: "descend", field: "createdAt" },
    filters,
  },
  _location,
) => {
  const filter = parseFilter(filters, _location);
  const params = new URLSearchParams({
    filter: JSON.stringify(filter),
    limit: pageSize,
    offset: (current - 1) * pageSize,
    order: sorter.order === "ascend" ? "ASC" : "DESC",
    orderBy: sorter.field || "createdAt",
  });
  const { data, xTotalCount } = await getRequest(`/sessions?${params}`);
  return { list: data, total: xTotalCount };
};

const fetchTags = async (user) => {
  const params = new URLSearchParams({
    filter: JSON.stringify({ userId: user.id }),
    order: "ASC",
    orderBy: "name",
  });
  const { data } = await getRequest(`/tags?${params}`);
  return data;
};

const fetchUserPreferences = async (user) => {
  try {
    const { preferences } = await getRequest(`/user-preferences/${user.id}`);
    return preferences;
  } catch (err) {
    if (err.code !== 404) {
      throw err;
    }
    return undefined;
  }
};

const updateUserPreferenceAdditionalColumns = async (id, additionalColumns) => {
  const { data } = await putRequest(`/user-preferences/${id}`, {
    id,
    preferences: { additionalColumns },
  });
  return data;
};

const createUserPreferenceAdditionalColumns = async (id, additionalColumns) => {
  const { data } = await postRequest("/user-preferences", {
    id,
    preferences: { additionalColumns },
  });
  return data;
};

export default {
  fetchSessions,
  fetchTags,
  fetchUserPreferences,
  updateUserPreferenceAdditionalColumns,
  createUserPreferenceAdditionalColumns,
};

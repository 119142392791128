import React from "react";
import {
  Button, Form, Input, message,
} from "antd";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";

import { AuthContext } from "../../AuthContextProvider";

const RegisterForm = () => {
  const { createUserWithEmailAndPassword } = React.useContext(AuthContext);

  const onSubmitHandler = async (user) => {
    try {
      await createUserWithEmailAndPassword(user);
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Form
      name="register"
      onFinish={onSubmitHandler}
      size="large"
    >
      <Form.Item
        name="name"
        hasFeedback
        rules={[{ required: true, message: "Required" }]}
      >
        <Input prefix={<UserOutlined />} placeholder="Name" />
      </Form.Item>
      <Form.Item
        name="email"
        hasFeedback
        rules={[
          { required: true, message: "Required" },
          { validateTrigger: "onBlur", type: "email", message: "Input is not a valid email address" },
        ]}
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: "Required" },
          { validateTrigger: "onBlur", min: 6, message: "Password should be at least 6 characters long" },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPassword"
        hasFeedback
        rules={[
          { required: true, message: "Required" },
          ({ getFieldValue }) => ({
            validator(rule, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords do not match!"));
            },
          }),
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Confirm password"
        />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Sign up
        </Button>
      </Form.Item>
    </Form>
  );
};

RegisterForm.propTypes = {};

export default RegisterForm;

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { getRequest } from "../../services/request";
import {
  CATEGORY_BODY_TYPE,
  CATEGORY_FITNESS_LEVEL,
  CATEGORY_GENDER,
  CATEGORY_MEDICAL_CONDITION,
  CATEGORY_UNIT,
} from "../../constants";

const initialState = {
  loading: true,
  error: false,
  user: null,
  references: [],
  keys: [],
  extent: [undefined, undefined],
};

const init = createAsyncThunk("app/init", async ({ userId }) => {
  const promises = [
    getRequest("/references"),
    getRequest("/measurements/keys"),
  ];
  if (userId) {
    promises.push(getRequest(`/users/${userId}`));
  }
  const [
    {
      value: { data: references },
    },
    { value: keys },
    userResult,
  ] = await Promise.allSettled(promises);
  return { user: (userResult && userResult.value) || null, references, keys };
});

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setExtent: (state, action) => {
      state.extent = action.payload;
    },
  },
  extraReducers: {
    [init.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [init.fulfilled]: (state, action) => {
      state.user = action.payload.user;
      state.references = action.payload.references;
      state.keys = action.payload.keys;
      state.loading = false;
    },
    [init.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

const substate = (state) => state.App || initialState;

const makeSelectUser = () => createSelector(substate, (sub) => sub.user);
const makeSelectLoading = () => createSelector(substate, (sub) => sub.loading);
const makeSelectError = () => createSelector(substate, (sub) => sub.error);
const makeSelectReferences = () => createSelector(substate, (sub) => sub.references);
const makeSelectKeys = () => createSelector(substate, (sub) => sub.keys);
const makeSelectGenderCategory = () => createSelector(substate, (sub) => sub.references.filter((ref) => ref.category === CATEGORY_GENDER));
const makeSelectFitnessLevelCategory = () => createSelector(substate, (sub) => sub.references.filter((ref) => ref.category === CATEGORY_FITNESS_LEVEL));
const makeSelectBodyTypeCategory = () => createSelector(substate, (sub) => sub.references.filter((ref) => ref.category === CATEGORY_BODY_TYPE));
const makeSelectMedicalConditionCategory = () => createSelector(substate, (sub) => sub.references.filter((ref) => ref.category === CATEGORY_MEDICAL_CONDITION));
const makeSelectUnitCategory = () => createSelector(substate, (sub) => sub.references.filter((ref) => ref.category === CATEGORY_UNIT));
const makeSelectExtent = () => createSelector(substate, (sub) => sub.extent);

const selectors = {
  makeSelectUser,
  makeSelectLoading,
  makeSelectError,
  makeSelectReferences,
  makeSelectKeys,
  makeSelectGenderCategory,
  makeSelectFitnessLevelCategory,
  makeSelectBodyTypeCategory,
  makeSelectMedicalConditionCategory,
  makeSelectUnitCategory,
  makeSelectExtent,
};

const { actions, reducer } = slice;

export { actions, selectors, init };
export default reducer;

/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createSelector } from "reselect";
import { putRequest } from "../../services/request";

const initialState = {
  loading: false,
  error: false,
};

const updateUser = createAsyncThunk(
  "profile/updateUser",
  async ({ user }) => {
    const updated = await putRequest(`/users/${user.id}`, user);
    return updated;
  },
);

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {

  },
  extraReducers: {
    [updateUser.pending]: (state, action) => {
      state.loading = true;
      state.error = false;
    },
    [updateUser.fulfilled]: (state, action) => {
      state.loading = false;
    },
    [updateUser.rejected]: (state, action) => {
      state.error = action.error;
      state.loading = false;
    },
  },
});

const substate = (state) => state.ProfilePage || initialState;

const makeSelectLoading = () => createSelector(substate, (sub) => sub.loading);
const makeSelectError = () => createSelector(substate, (sub) => sub.error);

const { actions, reducer } = slice;
const selectors = { makeSelectLoading, makeSelectError };

export { actions, selectors, updateUser };
export default reducer;

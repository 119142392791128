import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../services/request";

const parseFilter = (_filters = {}, location) => {
  const filter = {};
  for (let i = 0; i < Object.keys(_filters).length; i += 1) {
    const key = Object.keys(_filters)[i];
    if (_filters[key]) {
      filter[key] = _filters[key];
    }
  }

  const params = Object.fromEntries(
    new URLSearchParams(location?.search?.replace("?", "")),
  );
  for (let i = 0; i < Object.keys(params).length; i += 1) {
    const key = Object.keys(params)[i];
    if (params[key]) {
      filter[key] = params[key];
    }
  }

  return filter;
};

const fetchDevices = async (
  {
    current,
    pageSize,
    sorter = { order: "descend", field: "createdAt" },
    filters,
  },
  _location,
) => {
  const filter = parseFilter(filters, _location);
  const params = new URLSearchParams({
    filter: JSON.stringify(filter),
    limit: pageSize,
    offset: (current - 1) * pageSize,
    order: sorter.order === "ascend" ? "ASC" : "DESC",
    orderBy: sorter.field || "createdAt",
  });
  const { data, xTotalCount } = await getRequest(`/user-devices?${params}`);
  return { list: data, total: xTotalCount };
};

const createDevice = async (device) => {
  const { data } = await postRequest("/user-devices", device);
  return data;
};

const updateDevice = async (device) => {
  const { data } = await putRequest(`/user-devices/${device.macAddress}`, device);
  return data;
};

const deleteDevice = async (macAddress) => {
  await deleteRequest(`/user-devices/${macAddress}`);
};

export default {
  fetchDevices,
  createDevice,
  updateDevice,
  deleteDevice,
};

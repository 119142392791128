import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";

import { Card, Space, Typography } from "antd";
import { actions as appActions, selectors as appSelectors } from "../App/slice";
import UserForm from "../UserForm";
import { AuthContext } from "../Auth/AuthContextProvider";
import countries from "../../constants/countries";
import reducer, { insertUser } from "./slice";
import useAttachReducer from "../../store/useAttachReducer";

function OnboardPage() {
  useAttachReducer({ key: "OnboardPage", reducer });
  const dispatch = useDispatch();
  const { currentUser, signOut } = React.useContext(AuthContext);
  const genders = useSelector(appSelectors.makeSelectGenderCategory());
  const fitnessLevels = useSelector(appSelectors.makeSelectFitnessLevelCategory());
  const bodyTypes = useSelector(appSelectors.makeSelectBodyTypeCategory());
  const medicalConditions = useSelector(appSelectors.makeSelectMedicalConditionCategory());
  const units = useSelector(appSelectors.makeSelectUnitCategory());
  const user = useSelector(appSelectors.makeSelectUser());
  const error = useSelector(appSelectors.makeSelectError());

  const defaultValues = React.useMemo(() => ({
    name: currentUser.displayName || "",
    photoUrl: currentUser.photoURL || "",
    email: currentUser.email || "",
    phone: "",
    country: countries.find((country) => country.code === "US"),
    genderRefId: "",
    birthYear: "",
    birthMonth: "",
    fitnessLevelRefId: "",
    bodyTypeRefId: "",
    medicalConditionRefId: "",
    others: "",
    unitRefId: "",
    height: "",
    weight: "",
  }), [currentUser]);

  const onSubmit = async (data) => {
    const requestAction = await dispatch(insertUser(data));
    if (insertUser.fulfilled.match(requestAction)) {
      const inserted = unwrapResult(requestAction);
      dispatch(appActions.setUser(inserted));
    }
  };

  if (user && !error) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Helmet>
        <title>OnboardPage</title>
        <meta name="description" content="Description of OnboardPage" />
      </Helmet>
      <Card>
        <UserForm
          genders={genders}
          fitnessLevels={fitnessLevels}
          bodyTypes={bodyTypes}
          medicalConditions={medicalConditions}
          units={units}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
        />
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <Typography style={{ textAlign: "center" }}>
            <Typography.Link onClick={signOut}>Sign in</Typography.Link>
            <Typography.Text>
              {" "}
              to another account
            </Typography.Text>
          </Typography>
        </Space>
      </Card>
    </>
  );
}

OnboardPage.propTypes = {};

export default OnboardPage;

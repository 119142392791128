import React from "react";
// import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import { Card, message, Spin } from "antd";
import reducer, { updateUser, selectors } from "./slice";
import { selectors as appSelectors, actions as appActions } from "../App/slice";
import UserForm from "../UserForm";
import countries from "../../constants/countries";
import useAttachReducer from "../../store/useAttachReducer";
import { AuthContext } from "../Auth/AuthContextProvider";

function ProfilePage() {
  useAttachReducer({ key: "ProfilePage", reducer });

  const dispatch = useDispatch();
  const genders = useSelector(appSelectors.makeSelectGenderCategory());
  const fitnessLevels = useSelector(appSelectors.makeSelectFitnessLevelCategory());
  const bodyTypes = useSelector(appSelectors.makeSelectBodyTypeCategory());
  const medicalConditions = useSelector(appSelectors.makeSelectMedicalConditionCategory());
  const units = useSelector(appSelectors.makeSelectUnitCategory());
  const user = useSelector(appSelectors.makeSelectUser());
  const loading = useSelector(selectors.makeSelectLoading());
  const { currentUser } = React.useContext(AuthContext);

  const defaultValues = {
    ...user,
    others: (user && user.others) || "",
    country: countries.find((country) => country.code === ((user && user.country) || "US")),
  };

  const onSubmit = async (data) => {
    try {
      const resultAction = await dispatch(updateUser({ user: data }));
      const updated = unwrapResult(resultAction);
      dispatch(appActions.setUser(updated));
      if (currentUser.email !== data.email) {
        await message.loading("Logging you out as your email address has been updated");
        window.location.reload();
      } else {
        message.success("User profile updated");
      }
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>Profile Page</title>
        <meta name="description" content="Profile Page" />
      </Helmet>
      {user ? (
        <Spin spinning={loading}>
          <Card>
            <UserForm
              genders={genders}
              fitnessLevels={fitnessLevels}
              bodyTypes={bodyTypes}
              medicalConditions={medicalConditions}
              units={units}
              onSubmit={onSubmit}
              defaultValues={defaultValues}
            />
          </Card>
        </Spin>
      ) : null}
    </>
  );
}

// ProfilePage.propTypes = {};

export default ProfilePage;

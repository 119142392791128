/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { Redirect, useHistory } from "react-router-dom";
import {
  Card, Col, Row, Space, Typography,
} from "antd";

import ResetPasswordForm from "./ResetPasswordForm";
import { AuthContext } from "../AuthContextProvider";
import { selectors as appSelectors } from "../../App/slice";
import { SECONDARY_COLOR } from "../../../constants";

const ResetPasswordPage = () => {
  const user = useSelector(appSelectors.makeSelectUser());
  const loading = useSelector(appSelectors.makeSelectLoading());
  const { currentUser } = React.useContext(AuthContext);
  const history = useHistory();

  const onSignInClickHandler = () => {
    history.push("/login");
  };

  if (loading) {
    return (
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content="Reset Password" />
      </Helmet>
    );
  }

  if (currentUser && !user) {
    return <Redirect to="/onboard" />;
  }

  if (currentUser && user) {
    return <Redirect to="/" />;
  }

  return (
    <div>
      <Helmet>
        <title>Reset Password</title>
        <meta name="description" content="Reset Password" />
      </Helmet>
      <Row justify="center" align="middle" style={{ height: "100vh", background: SECONDARY_COLOR }}>
        <Col xxl={5} xl={6} lg={8} md={12} sm={12} xs={20}>
          <Card>
            <Typography>
              <Typography.Title>Reset</Typography.Title>
            </Typography>
            <ResetPasswordForm />
            <Space direction="vertical" size="large" style={{ width: "100%" }}>
              <Typography style={{ textAlign: "center" }}>
                <Typography.Link onClick={onSignInClickHandler}>Sign in</Typography.Link>
              </Typography>
            </Space>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;

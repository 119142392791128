import { getRequest, postRequest, putRequest } from "../services/request";

const fetch = async (user) => {
  try {
    const { preferences } = await getRequest(`/user-preferences/${user.id}`);
    return preferences;
  } catch (err) {
    if (err.code !== 404) {
      throw err;
    }
    return undefined;
  }
};

const update = async (id, preferences) => {
  const { data } = await putRequest(`/user-preferences/${id}`, {
    id,
    preferences,
  });
  return data;
};

const create = async (id, preferences) => {
  const { data } = await postRequest("/user-preferences", { id, preferences });
  return data;
};

export default {
  fetch,
  update,
  create,
};

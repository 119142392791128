import { getRequest } from "../services/request";

const fetch = async (macAddress, startTime, endTime) => {
  try {
    const sensorData = await getRequest(
      `/sensors-data/query?macAddress=${macAddress}&startTime=${startTime}&endTime=${endTime}`
      // `/sensors-data/query?macAddress=${macAddress}&startTime=2024-03-23T00:00:00.000Z&endTime=2024-03-23T00:20:00.000Z`,
    );
    return sensorData;
  } catch (err) {
    if (err.code !== 404) {
      throw err;
    }
    return undefined;
  }
};

const fetchInflux = async (
  macAddress,
  startTime,
  endTime,
  aggregateInterval
) => {
  try {
    const sensorDataInflux = await getRequest(
      `/sensors-data/influx/query?macAddress=${macAddress}&startTime=${startTime}&endTime=${endTime}${aggregateInterval ? `&aggregateInterval=${aggregateInterval}` : ""}`
      // `/sensors-data/query?macAddress=${macAddress}&startTime=2024-03-23T00:00:00.000Z&endTime=2024-03-23T00:20:00.000Z`,
    );
    return sensorDataInflux;
  } catch (err) {
    if (err.code !== 404) {
      throw err;
    }
    return undefined;
  }
};

export default {
  fetch,
  fetchInflux,
};

import React from "react";
import { useHistory } from "react-router";
import {
  Button, Checkbox, Form, Input, message, Typography,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";

import { AuthContext } from "../../AuthContextProvider";

const EmailSignInForm = ({ onSubmit, onSubmitComplete }) => {
  const [rememberMe, setRememberMe] = React.useState(
    localStorage.getItem("email") && true,
  );
  const { signInWithEmailAndPassword } = React.useContext(AuthContext);
  const history = useHistory();

  const defaultValues = {
    email: localStorage.getItem("email") || "",
    password: "",
  };

  const onSubmitHandler = async (values) => {
    const { email, password } = values;
    try {
      onSubmit();
      await signInWithEmailAndPassword(email, password);
      if (rememberMe) {
        localStorage.setItem("email", email);
      } else {
        localStorage.removeItem("email");
      }
    } catch (err) {
      message.error("Invalid credentials");
    } finally {
      onSubmitComplete();
    }
  };

  const onRememberMeCheckedHandler = (e) => {
    setRememberMe(e.target.checked);
  };

  const onForgotClickHandler = () => {
    history.push("/forgot");
  };

  return (
    <Form
      name="email_login"
      initialValues={defaultValues}
      onFinish={onSubmitHandler}
      size="large"
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Required" },
          { validateTrigger: "onBlur", type: "email", message: "Input is not a valid email address" },
        ]}
      >
        <Input prefix={<UserOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Required" },
          { validateTrigger: "onBlur", min: 6, message: "Password should be at least 6 characters long" },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Checkbox
          onChange={onRememberMeCheckedHandler}
          checked={rememberMe}
        >
          Remember me
        </Checkbox>

        <Typography.Link onClick={onForgotClickHandler} style={{ float: "right" }}>
          Forgot password
        </Typography.Link>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Log in
        </Button>
      </Form.Item>
    </Form>
  );
};

EmailSignInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
};

export default EmailSignInForm;

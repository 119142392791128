import {
  Button, Card, Col, List, Modal, Row, Spin, Typography,
} from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getRequest, postRequest } from "../../../services/request";
import { selectors } from "../../App/slice";
import UserAutoComplete from "../../Common/UserAutoComplete";
import UserAvatar from "../../Common/UserAvatar";

const UsersList = () => {
  const user = useSelector(selectors.makeSelectUser());
  const [sharedUsers, setSharedUsers] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();
  const [selected, setSelected] = React.useState([]);

  const init = React.useCallback(() => {
    setLoading(true);
    const params = new URLSearchParams({ filter: JSON.stringify({ sharedWithId: user.id }) });
    getRequest(`/shared-users?${params}`).then(({ data }) => {
      setSharedUsers(data);
      setLoading(false);
    });
  }, [user]);

  React.useEffect(() => { init(); }, [init]);

  const onSubmitHandler = async () => {
    try {
      if (selected.length > 0) {
        setSubmitting(true);
        try {
          await Promise.all(selected.map((e) => postRequest("/shared-users", { userId: e.key, sharedWithId: user.id })));
          setSubmitting(false);
          setShow(false);
          init();
        } catch (err) {
          setSubmitting(false);
        }
      }
    } catch (err) {
      //
    }
  };

  const onViewSessionsClick = (id) => () => {
    history.push({ pathname: "/sessions", search: `?owner=${id}` });
  };

  const onSelectHandler = (val, { key, value }) => {
    setSelected((prev) => [...prev, { key, value }]);
  };

  const onDeselectHandler = (value) => {
    setSelected(selected.filter((e) => e.value !== value));
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "16px" }}>
        <Col>
          <Typography.Title level={4}>Users shared with me</Typography.Title>
          <Typography.Text>
            Users that are explicitly shared with you or added by you
          </Typography.Text>
        </Col>
        <Col>
          <Button type="primary" onClick={() => { setShow(true); }}>Add user</Button>
        </Col>
      </Row>
      <Spin spinning={loading}>
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 3,
          }}
          dataSource={sharedUsers}
          renderItem={(u) => (
            <List.Item key={u.owner.email}>
              <Card>
                <Row gutter={24}>
                  <List.Item.Meta
                    avatar={<UserAvatar {...u.owner} size="large" />}
                    title={u.owner.name || u.owner.email}
                    description={u.owner.email}
                  />
                  <Button onClick={onViewSessionsClick(u.owner.id)}>View Sessions</Button>
                </Row>
              </Card>
            </List.Item>
          )}
        />
      </Spin>
      <Modal
        visible={show}
        title="Add a user"
        onOk={onSubmitHandler}
        confirmLoading={submitting}
        onCancel={() => { setShow(false); }}
      >
        <UserAutoComplete
          onSelect={onSelectHandler}
          onDeselect={onDeselectHandler}
        />
      </Modal>
    </>
  );
};

export default UsersList;

import React from "react";
import { Row, Space, Spin } from "antd";

import Logo from "../AppLayout/mainLogo.png";
import { SECONDARY_COLOR, PRIMARY_COLOR } from "../../../constants";

const Splash = () => (
  <Row
    justify="center"
    align="middle"
    style={{ height: "100vh", background: SECONDARY_COLOR }}
  >
    <Space
      direction="vertical"
      size="large"
      style={{ width: "100%", textAlign: "center" }}
    >
      <img src={Logo} alt="HyperCanopy logo" height={84} width={84} />
      <Spin size="large" />
    </Space>
  </Row>
);
export default Splash;

import React from "react";
import {
  DashboardOutlined, AlignLeftOutlined, ShareAltOutlined, UserOutlined, SettingOutlined,
  LineChartOutlined, FieldTimeOutlined,
} from "@ant-design/icons";

export default [
  { name: "Dashboard", path: "/", icon: <DashboardOutlined /> },
  { name: "Sessions", path: "/sessions", icon: <AlignLeftOutlined /> },
  { name: "Schedules", path: "/schedules", icon: <FieldTimeOutlined /> },
  { name: "Trends", path: "/trends", icon: <LineChartOutlined /> },
  { name: "Sharing", path: "/sharing", icon: <ShareAltOutlined /> },
  { name: "Profile", path: "/profile", icon: <UserOutlined /> },
  { name: "Settings", path: "/settings", icon: <SettingOutlined /> },
];

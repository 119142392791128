import React from "react";
import { useHistory, useLocation } from "react-router";
import {
  Button, Form, Input, message,
} from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";

import { AuthContext } from "../../AuthContextProvider";

const ResetPasswordForm = () => {
  const { confirmPasswordReset } = React.useContext(AuthContext);
  const history = useHistory();
  const { state: { code, email } } = useLocation();

  const defaultValues = { email };

  const onSubmitHandler = async ({ password }) => {
    await confirmPasswordReset(code, password);
    message.success("Password reset successful");
    history.push("/login");
  };

  return (
    <Form
      name="reset"
      onFinish={onSubmitHandler}
      size="large"
      initialValues={defaultValues}
    >
      <Form.Item
        name="email"
        disabled
      >
        <Input prefix={<MailOutlined />} placeholder="Email" />
      </Form.Item>
      <Form.Item
        name="password"
        hasFeedback
        rules={[
          { required: true, message: "Required" },
          { validateTrigger: "onBlur", min: 6, message: "Password should be at least 6 characters long" },
        ]}
      >
        <Input
          prefix={<LockOutlined />}
          type="password"
          placeholder="Password"
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
          Reset
        </Button>
      </Form.Item>
    </Form>
  );
};

ResetPasswordForm.propTypes = {};

export default ResetPasswordForm;

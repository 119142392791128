import { getRequest } from "../services/request";

const fetchTrends = (tags = [], dateRange = [], userId, interval = "1d", duration) => {
  const params = new URLSearchParams({
    filter: JSON.stringify({
      tags,
      dateRange: dateRange.map((e) => e.toISOString()),
      userId,
      interval,
      duration: duration?.map((e) => e.hour()),
    }),
  });
  return getRequest(`/measurements/trends?${params}`);
};

export default { fetchTrends };

import React from "react";
import PropTypes from "prop-types";
import {
  Form, Input, message, Modal, Spin,
} from "antd";
import { CirclePicker } from "react-color";

const ColorInput = ({ value, onChange }) => {
  const [color, setColor] = React.useState(value);

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({ value: changedValue });
    }
  };

  const onColorChange = (_color) => {
    setColor(_color);
    triggerChange(_color);
  };

  return (
    <>
      <div style={{ height: 36, marginBottom: 16 }}>
        {color
          ? (
            <div style={{
              height: "100%",
              width: 100,
              padding: 6,
              borderRadius: 1,
              borderWidth: 1,
              boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
              display: "inline-block",
            }}
            >
              <div style={{
                background: color?.hex || color,
                height: "100%",
                width: "100%",
                borderRadius: 2,
              }}
              />
            </div>
          )
          : <p style={{ margin: 0 }}>No color selected</p>}
      </div>
      <CirclePicker color={color} onChangeComplete={onColorChange} />
    </>
  );
};

ColorInput.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
};

const AreaModal = (props) => {
  const {
    open, onClose, onDefineArea,
  } = props;
  const area = typeof open === "object" ? open : undefined;
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = React.useState(false);

  const onSubmitHandler = async () => {
    try {
      const values = await form.validateFields();
      setSubmitting(true);
      await onDefineArea({
        id: open?.id,
        name: values.name,
        color: values.color.value ? values.color.value.hex : values.color,
      });
      setSubmitting(false);
      onClose();
    } catch (err) {
      message.error(err.message);
    }
  };

  return (
    <Modal
      visible={open}
      onCancel={onClose}
      title="Define area"
      maskClosable={false}
      okText="Save"
      onOk={onSubmitHandler}
    >
      <Spin spinning={submitting}>
        <Form
          form={form}
          name="define_area"
          layout="vertical"
          initialValues={area}
          size="small"
        >
          <Form.Item name="name" label="Area name" rules={[{ required: true, message: "Please input the name of the area" }]}>
            <Input />
          </Form.Item>
          <Form.Item
            name="color"
            label="Color"
            rules={[{ required: true, message: "Please select the color of the area" }]}
          >
            <ColorInput />
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
};

AreaModal.propTypes = {
  open: PropTypes.any.isRequired,
  onClose: PropTypes.func.isRequired,
  onDefineArea: PropTypes.func.isRequired,
};

export default AreaModal;
